/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { UserIcon } from '@heroicons/react/24/solid'

// Components
import { AuthGradient } from '../../components/AuthGradient'
import { AuthSplash } from '../../components/AuthSplash'
import { Button } from '../../components/Button'
import { KeyIcon } from '../../components/KeyIcon'
import { OrganizationIcon } from '../../components/OrganizationIcon'
import { PasswordInput } from '../../components/PasswordInput'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Images
import Fetch from '../../assets/images/fetch_text_logo.svg'
import Shield from '../../assets/images/shield_security.svg'
import SMS from '../../assets/images/sms.svg'
import EventstackLogo from '../../assets/images/eventstack_logo.svg'

// Service
import { forgotPassword, login, loginConfirm } from '../../services/user.service'
import { createEventRegistration, getEventRegistration } from '../../services/events.service'

// Util & Stores
import { toast } from '../../utils/helpers'
import { UserStoreContext } from '../../stores/UserStore'

/**
 *
 * EventRegistration
 *
 */
const EventRegistration = () => {
  // Context
  const navigate = useNavigate()
  const { eventId } = useParams()
  const { isAuthenticated, isEEUser, user, getUpdatedUser, setCurrentUser, setCurrentTokens } =
    useContext(UserStoreContext)

  // State
  const [initialLoading, setInitialLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [eventRegistrationDetails, setEventRegistrationDetails] = useState(null)

  /**
   * Can be one of: `authenticated`, `noAccount`, `hasAccount` and `verifying`
   */
  const [accountStatus, setAccountStatus] = useState(null)

  /**
   * Can be one of: `request`, `register`
   */
  const [accessStatus, setAccessStatus] = useState(null)

  const handleSuccess = (m) => toast(m, 'success')
  const handleError = (m) => toast(m, 'error')

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    register,
    reset,
  } = useForm({
    email: '',
  })

  useEffect(() => {
    const getUpdatedData = async () => {
      const eventDetails = await getEventRegistration(eventId, null, setError, setInitialLoading)

      // When authenticated, check if the user has event access through an organization
      if (isAuthenticated && eventDetails.organizationIdWithAccess) {
        navigate(
          `/exhibitor/${eventDetails.organizationIdWithAccess}/dashboard?onboarding=${eventId}`,
        )
      } else if (isAuthenticated && isEEUser) {
        navigate('/dashboard')
      }
      // Otherwise, log the user as authenticated and default to registering
      else if (isAuthenticated) {
        reset({ email: user.email })
        setAccountStatus('authenticated')
        setAccessStatus('register')
      }

      if (eventDetails) {
        setEventRegistrationDetails(eventDetails)
      }
    }

    getUpdatedData()
  }, [isAuthenticated])

  const configureSubmitButtonlabel = () => {
    if (accountStatus === 'hasAccount') return 'Sign in'
    if (accountStatus === 'noAccount' && accessStatus === null) return 'Confirm and Create Account'
    if (accountStatus === 'noAccount' && accessStatus === 'request') return 'Request Access'
    return 'Submit'
  }

  /**
   * Handles form submission
   * - Redirects to event home page when successful (with account)
   * - Displays success message when registering or requesting access without an account
   * @param {object} data
   */
  const onSubmit = async (data) => {
    // If the user is not authenticated, we need to check if they have an account
    if (!accountStatus && accessStatus === null) {
      const response = await getEventRegistration(eventId, data.email, setError, setLoading)

      // If the user already exists and is not authenticated, we need them to login
      if (response === 'User exists') {
        setAccountStatus('hasAccount')
      }
      // If the user is not authenticated and does not have an account, we'll need to have the user
      // either register for an Exhibitor that has a matching domain, or request access to an Exhibitor
      else if (response) {
        setEventRegistrationDetails(response)
        setAccountStatus('noAccount')

        // If there are no exhibitors with matching domains, this will be a request for access
        if (response.availableDomains.length === 0) {
          setAccessStatus('request')
        } else {
          setAccessStatus('register')
        }
      }
    }
    // User has an account but is not logged in
    else if (accountStatus === 'hasAccount') {
      const response = await login(
        null,
        {
          email: data.email,
          password: data.password,
        },
        handleError,
        setLoading,
      )

      if (response) {
        setCurrentUser({ email: data.email, ...response })
        setAccountStatus('verifying')
      }
    }
    // Complete authentication MFA
    else if (accountStatus === 'verifying') {
      const response = await loginConfirm(
        { code: data.code, email: user.email },
        handleError,
        setLoading,
        setCurrentTokens,
        getUpdatedUser,
      )

      if (response) {
        setAccountStatus('authenticated')
      }
    }
    // Handle registering or requesting access to an event
    else {
      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
      }

      if (accountStatus === 'noAccount') {
        payload.email = data.email
      }

      if (accessStatus === 'request') {
        payload.type = 'request'
        payload.organizationName = data.organizationName
      } else if (accessStatus === 'register') {
        payload.type = 'register'
      }

      if (data.organization) {
        payload.organizationId = data.organization.id
      } else if (eventRegistrationDetails.organizationRoles.length === 1) {
        payload.organizationId = eventRegistrationDetails.organizationRoles[0].organizationId
      } else if (eventRegistrationDetails.availableDomains.length === 1) {
        payload.organizationId = eventRegistrationDetails.availableDomains[0].id
      }

      await createEventRegistration(eventId, payload, handleError, setLoading, (m) => {
        // Display success message when registering or requesting access without an account
        if (m.message?.includes('New user')) {
          handleSuccess('Account created. Check your email to finish registration.')
          setAccountStatus(null)
          setAccessStatus(null)
          reset()
        } else if (m.status?.includes('Registration')) {
          handleSuccess('Request received.')
          setAccountStatus(null)
          setAccessStatus(null)
          reset()
        }
        // Otherwise, redirect to the Exhibitor's dashboard
        else {
          handleSuccess('Access granted. Redirecting...')
          navigate(`/exhibitor/${payload.organizationId}/dashboard?onboarding=${eventId}`)
        }
      })
    }
  }

  const renderOrganizationNameInput = () => (
    <TextInput
      className="flex h-12 w-full rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
      error={errors.organizationName && 'This field is required'}
      icon={
        <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
          <OrganizationIcon className="h-5 stroke-white" />
        </div>
      }
      id="organizationName"
      inputStyles="bg-transparent text-white text-md sm:text-base"
      name="organizationName"
      placeholder="Exhibitor Name"
      type="organizationName"
      {...register('organizationName', { required: true })}
    />
  )

  const renderNameInputs = () => (
    <div className="mb-4 flex w-full flex-row gap-4">
      <TextInput
        className="flex h-12 w-full rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
        error={errors.firstName && 'This field is required'}
        id="firstName"
        icon={
          <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
            <UserIcon className="h-5 fill-white" />
          </div>
        }
        fullWidth
        inputStyles="bg-transparent text-white text-md sm:text-base disabled:bg-transparent"
        name="firstName"
        placeholder="First Name"
        type="firstName"
        {...register('firstName', { required: true })}
      />

      <TextInput
        className="flex h-12 w-full rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
        error={errors.lastName && 'This field is required'}
        id="lastName"
        icon={
          <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
            <UserIcon className="h-5 fill-white" />
          </div>
        }
        fullWidth
        inputStyles="bg-transparent text-white text-md sm:text-base disabled:bg-transparent"
        name="lastName"
        placeholder="Last Name"
        type="lastName"
        {...register('lastName', { required: true })}
      />
    </div>
  )

  const renderOrganizationSelect = () => (
    <Controller
      name="organization"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          className="flex h-12 w-full items-center rounded-md border-gray bg-gray py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
          dark
          disabled={loading}
          error={errors.organization && 'This field is required'}
          fullWidth
          icon={
            <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
              <OrganizationIcon className="h-5 stroke-white" />
            </div>
          }
          id="organization"
          inputClassName="pl-0 text-white"
          name="organization"
          nunito
          onChange={onChange}
          options={eventRegistrationDetails.organizationRoles.map((d) => ({
            label: d.organization,
            id: d.organizationId,
          }))}
          placeholder="Select an Exhibitor"
          style={{ flex: true, width: '100%' }}
          value={value}
        />
      )}
      rules={{ required: true }}
    />
  )

  /**
   * Renders fields for when a user doesn't have an account.
   */
  const renderRequestFields = () => {
    // No matching exhibitor to register under
    if (eventRegistrationDetails.availableDomains.length === 0) {
      return (
        <div className="space-y-4">
          {renderNameInputs()}

          <span className="text-sm">
            This email address does not match any existing Exhibitors. Please enter the name of the
            Exhibitor you would like to register with to request access.
          </span>

          {renderOrganizationNameInput()}
        </div>
      )
    }

    // Only one exhibitor to register under
    if (eventRegistrationDetails.availableDomains.length === 1) {
      return (
        <div className="space-y-4">
          {renderNameInputs()}

          <span className="text-sm">
            This email is associated with{' '}
            <b>{eventRegistrationDetails.availableDomains[0].name}</b>. Confirm that you would like
            to create an account and register for {eventRegistrationDetails.name} under this
            exhibitor.
          </span>
        </div>
      )
    }

    // Multiple exhibitors to register under
    return renderOrganizationSelect()
  }

  /**
   * Renders fields for when a user has an account.
   */
  const renderRegisterFields = () => {
    // No matching exhibitor to register under
    if (eventRegistrationDetails.organizationRoles.length === 0) {
      return (
        <div className="space-y-4">
          <span className="text-sm">
            This email address does not match any existing Exhibitors. Please enter the name of the
            Exhibitor you would like to register with to request access.
          </span>

          {renderOrganizationNameInput()}
        </div>
      )
    }

    // Only one exhibitor to register under
    if (eventRegistrationDetails.organizationRoles.length === 1) {
      return (
        <span className="text-sm">
          This email is associated with{' '}
          <b>{eventRegistrationDetails.organizationRoles[0].organization}</b>. Confirm that you
          would like to register for {eventRegistrationDetails.name} under this exhibitor.
        </span>
      )
    }

    if (accessStatus === 'register') {
      // Multiple exhibitors to register under
      return renderOrganizationSelect()
    }

    return null
  }

  const hasAccount = accountStatus && accountStatus !== 'noAccount'

  const renderEventRegistrationFields = () => {
    if (
      eventRegistrationDetails &&
      accountStatus !== null &&
      !hasAccount &&
      accessStatus === 'register'
    ) {
      return renderRequestFields()
    }

    if (
      eventRegistrationDetails &&
      accountStatus === 'authenticated' &&
      accessStatus === 'register'
    ) {
      return renderRegisterFields()
    }

    return null
  }

  const canRegisterWithAnotherExhibitor =
    eventRegistrationDetails?.availableDomains.length === 1 ||
    eventRegistrationDetails?.organizationRoles.length === 1

  return (
    <div className="flex h-screen w-screen">
      <div className="hidden xl:block xl:basis-1/2">
        <AuthSplash exhibitor />
      </div>

      <div className="relative flex h-full basis-full flex-col justify-between overflow-hidden bg-[#181823] p-8 text-white xl:basis-1/2">
        <AuthGradient />

        <div className="w-[300px] self-center">
          <img src={Fetch} alt="" className="h-16" />

          <div className="flex items-center justify-end gap-x-3">
            <span className="font-bold">Powered by</span>{' '}
            <img src={EventstackLogo} alt="" className="h-6" />
          </div>
        </div>

        <StateContainer loading={initialLoading} error={error} dark>
          <div className="my-auto max-w-[700px] sm:mx-auto sm:w-4/5">
            <h2 className="text-3xl font-bold">
              Join Fetch Leads for {eventRegistrationDetails?.name}
            </h2>

            <form
              className="z-1 relative mt-8 flex max-h-[450px] flex-col gap-4 overflow-y-auto"
              id="login-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Form Fields */}
              <div className="space-y-6">
                <div className="mb-6 flex flex-col">
                  <TextInput
                    className="flex h-12 w-full rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
                    data-testid="email"
                    disabled={accountStatus !== null}
                    error={errors.email && 'This field is required'}
                    autoComplete="email"
                    id="email"
                    icon={
                      <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
                        <img src={SMS} alt="email" className="h-5 stroke-purple" />
                      </div>
                    }
                    inputStyles="bg-transparent text-white text-md sm:text-base disabled:bg-transparent"
                    name="email"
                    placeholder="Email"
                    type="email"
                    {...register('email', { required: true })}
                  />

                  {accountStatus && accountStatus !== 'authenticated' && (
                    <Button
                      background="bg-transparent"
                      className="self-end text-purple hover:bg-transparent"
                      plain
                      onClick={() => {
                        setAccountStatus(null)
                        setAccessStatus(null)
                        reset()
                      }}
                      label="Change Email"
                      type="button"
                    />
                  )}

                  {(accountStatus === 'hasAccount' || accountStatus === 'verifying') && (
                    <div className="flex flex-col gap-4">
                      <p className="mt-2 text-center text-sm">
                        An account with this email already exists. Log in to continue.
                      </p>

                      <div className="flex flex-col gap-2">
                        <Controller
                          disabled={accountStatus === 'verifying'}
                          error={errors.password && 'This field is required'}
                          name="password"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <PasswordInput
                              id="password"
                              className="flex h-12 justify-center rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
                              icon={
                                <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
                                  <img src={Shield} alt="password" className="h-5 stroke-purple" />
                                </div>
                              }
                              inputStyles="bg-transparent text-white text-md sm:text-base disabled:bg-transparent"
                              placeholder="Password"
                              renderIconStyles="fill-white"
                              autoComplete="new-password"
                              {...field}
                            />
                          )}
                        />

                        {accountStatus === 'hasAccount' && (
                          <div className="flex flex-row items-center justify-end -space-x-1.5">
                            <span className="text-sm">First time using Fetch? </span>
                            <Button
                              background="bg-transparent"
                              className="text-purple hover:bg-transparent"
                              plain
                              loading={loading}
                              onClick={async () => {
                                await forgotPassword(
                                  { email: getValues('email') },
                                  'exhibitor',
                                  handleError,
                                  setLoading,
                                  handleSuccess,
                                )

                                setAccountStatus(null)
                                setAccessStatus(null)
                                reset()
                              }}
                              label="Request Reset Password"
                              type="button"
                            />
                          </div>
                        )}
                      </div>

                      {accountStatus === 'verifying' && (
                        <>
                          <p className="mb-4 mt-2 text-center text-sm">
                            Enter the verification code sent to{' '}
                            <span className="font-bold">{user.address}</span>.
                          </p>

                          <TextInput
                            className="flex h-12 w-full rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
                            icon={
                              <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
                                <KeyIcon className="h-5 stroke-white" />
                              </div>
                            }
                            data-testid="code"
                            error={errors.code && 'This field is required'}
                            fullWidth
                            id="code"
                            inputStyles="bg-transparent text-white text-md sm:text-base"
                            name="code"
                            nunito
                            placeholder="Verification Code"
                            {...register('code', { required: true })}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>

                {renderEventRegistrationFields()}

                {accessStatus === 'request' && (
                  <div className="space-y-4">
                    {renderNameInputs()}

                    <span className="text-sm">
                      Please enter the name of the Exhibitor you would like to register with.
                    </span>

                    {renderOrganizationNameInput()}
                  </div>
                )}
              </div>

              <Button
                className="text-md flex h-12 shrink-0 items-center rounded-md border-purple sm:h-[60px] sm:text-base"
                fullWidth
                label={configureSubmitButtonlabel()}
                loading={loading}
                type="submit"
              />

              {accountStatus && accessStatus !== 'request' && canRegisterWithAnotherExhibitor && (
                <Button
                  background="bg-purple-100"
                  className="text-md flex h-12 shrink-0 items-center rounded-md border-purple bg-transparent text-purple hover:bg-purple hover:text-white sm:h-[60px] sm:text-base"
                  fullWidth
                  label="Register with a Different Exhibitor"
                  onClick={() => setAccessStatus('request')}
                  loading={loading}
                />
              )}

              <span className="text-center text-sm">
                Having trouble? Contact us at{' '}
                <a className="text-purple" href="mailto:info@eventstack.co">
                  info@eventstack.co
                </a>
                .
              </span>
            </form>
          </div>
        </StateContainer>
      </div>
    </div>
  )
}

export default EventRegistration
