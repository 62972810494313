/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  ChatBubbleBottomCenterTextIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline'
import { InformationCircleIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { Modal } from '../../components/Modal'
import { Popup } from '../../components/Popup'
import { TextInput } from '../../components/TextInput'

// Service
import { getGlobalPopups } from '../../services/global.service'
import { getCustomPopups } from '../../services/kiosks.service'

// Utils
import { toast } from '../../utils/helpers'
import { NON_SYNCED_FIELDS } from '../../utils/constants'
import CustomPopupModal from './CustomPopupModal'

const TABS = [
  {
    id: 0,
    name: 'Attendee',
  },
  {
    id: 1,
    name: 'Staff',
  },
]

dayjs.extend(relativeTime)

/**
 *
 * Popups
 *
 */
const Popups = ({ event, kioskConfiguration, setKioskConfiguration }) => {
  const mergeFields =
    event.enableRegistrationSync && event.enabledCustomFields
      ? [...NON_SYNCED_FIELDS, ...event.enabledCustomFields]
      : NON_SYNCED_FIELDS

  // State
  const [customPopups, setCustomPopups] = useState([])
  const [loadingCustomPopups, setLoadingCustomPopups] = useState(false)
  const [globalPopups, setGlobalPopups] = useState([])
  const [tabs, setTabs] = useState(TABS)
  const [active, setActive] = useState(TABS[0])
  const [showMergeFieldModal, setShowMergeFieldModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredMergeFields, setFilteredMergeFields] = useState(mergeFields)
  const [showCustomPopupModal, setShowCustomPopupModal] = useState(false)
  const [editCustomPopup, setEditCustomPopup] = useState(null)

  // Ref
  const searchInputRef = useRef()

  const handleSuccess = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  const getCustomPopupMessages = async () => {
    const results = await getCustomPopups(
      event.id,
      kioskConfiguration.id,
      handleErrors,
      setLoadingCustomPopups,
    )
    setCustomPopups(results)
  }

  useEffect(() => {
    const welcomePopup = _.find(
      kioskConfiguration.kioskPopups,
      (p) => p.identifier === 'attendee-welcome',
    )
    if (welcomePopup) {
      setTabs([
        ...TABS,
        {
          id: 2,
          name: 'Custom',
        },
      ])
    }

    const getDefaultPopups = async () => {
      const results = await getGlobalPopups(
        () => {},
        () => {},
      )

      setGlobalPopups(results)
    }

    getCustomPopupMessages()
    getDefaultPopups()
  }, [])

  const renderTableText = (text) => <span className="text-sm text-dark">{text}</span>

  const renderPopups = () => {
    const filteredPopups = _.filter(kioskConfiguration.kioskPopups, (p) =>
      _.includes(p.identifier, _.lowerCase(active.name)),
    )

    return _.map(filteredPopups, (popup, i) => (
      <div className="py-2" key={`${popup.id}`}>
        <Popup
          defaultPopup={_.find(globalPopups, { identifier: popup.identifier })}
          eventId={event.id}
          kioskId={kioskConfiguration.id}
          popup={popup}
          updateData={(updatedPopup) => {
            const updatedPopups = _.map(kioskConfiguration.kioskPopups, (p) =>
              p.id === updatedPopup.id ? updatedPopup : p,
            )
            setKioskConfiguration({ ...kioskConfiguration, kioskPopups: updatedPopups })
          }}
        />
        {i !== filteredPopups.length - 1 && <hr className="mt-6 border-gray-300" />}
      </div>
    ))
  }

  return (
    <div className="flex h-full flex-col py-6">
      <div className="flex w-full flex-row items-start justify-between px-8">
        <div className="flex flex-row space-x-6">
          <div className="text-2xl font-bold">Pop-Ups</div>

          <nav className="flex space-x-2 sm:space-x-6" aria-label="Tabs">
            {tabs.map((tab) => {
              const activeTab = active.id === tab.id

              return (
                <button
                  type="button"
                  key={tab.name}
                  onClick={() => setActive(tab)}
                  className={mergeClassNames(
                    activeTab
                      ? 'border-purple-700 text-purple-700'
                      : 'border-white text-gray-500 hover:border-purple-700 hover:text-purple-700',
                    'border-b-2 text-sm font-medium sm:px-2',
                  )}
                  aria-current={activeTab ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              )
            })}
          </nav>
        </div>

        {active.id !== 2 && (
          <Button
            background="bg-purple-100"
            className="border-purple-100 text-purple-700"
            label="Merge Fields"
            onClick={() => setShowMergeFieldModal(true)}
          />
        )}
      </div>

      <div className="flex h-full flex-col overflow-y-auto px-8">
        {active.id === 2 ? (
          <div className="flex flex-col gap-2 py-2">
            <div className="flex flex-row items-center justify-between py-2">
              <span className="text-lg font-semibold">Custom Pop-Up Messages</span>

              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                icon={<ChatBubbleBottomCenterTextIcon className="h-5 stroke-white" />}
                label="Add Custom Pop-Up"
                onClick={() => setShowCustomPopupModal(true)}
              />
            </div>

            <div className="flex w-fit items-center rounded-md bg-blue-50 px-2">
              <InformationCircleIcon className="mr-2 h-5 text-blue-800" />
              <span className="py-2 text-sm font-medium text-blue-dark">
                All custom popup messages will be displayed in the Welcome popup.
              </span>
            </div>

            <DataTable
              columns={[
                {
                  id: 'audience',
                  name: 'Audience',
                  selector: (row) => row.audience,
                  cell: (row) => {
                    let background = 'bg-status-green'
                    let text = 'text-role-admin'

                    if (row.audience === 'Staff') {
                      background = 'bg-purple-100'
                      text = 'text-purple'
                    } else if (row.audience === 'Both') {
                      background = 'bg-blue-100'
                      text = 'text-blue'
                    }

                    return (
                      <div
                        className={mergeClassNames(
                          'shrink-0 rounded-full px-2.5 py-0.5',
                          background,
                        )}
                      >
                        <span className={mergeClassNames('text-xs font-bold', text)}>
                          {row.audience}
                        </span>
                      </div>
                    )
                  },
                  width: '100px',
                },
                {
                  id: 'message',
                  grow: 1,
                  name: 'Message',
                  selector: (row) => row.message,
                  cell: (row) => renderTableText(row.message),
                  minWidth: '150px',
                },
                {
                  id: 'fields',
                  grow: 1,
                  name: 'Fields Used',
                  selector: (row) => row.popupMessageLogic,
                  cell: (row) =>
                    renderTableText(
                      _.join(
                        _.map(row.popupMessageLogic, (l) => l.mergeField),
                        ', ',
                      ),
                    ),
                  minWidth: '150px',
                },
                {
                  id: 'edit',
                  name: '',
                  cell: (row) => (
                    <button
                      className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5 disabled:cursor-not-allowed disabled:opacity-50"
                      type="button"
                      onClick={() => {
                        setEditCustomPopup(row)
                        setShowCustomPopupModal(true)
                      }}
                    >
                      Edit
                    </button>
                  ),
                  center: true,
                  width: '80px',
                },
              ]}
              data={customPopups}
              progressPending={loadingCustomPopups}
            />
          </div>
        ) : (
          renderPopups()
        )}
      </div>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              setFilteredMergeFields(mergeFields)
              setShowMergeFieldModal(false)
            },
          },
        ]}
        icon={<ClipboardDocumentListIcon className="h-5 stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex max-h-56 flex-col sm:mt-5">
            <TextInput
              autoComplete="off"
              className="w-[90%] self-center rounded-lg py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-purple" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      setFilteredMergeFields(mergeFields)
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                setFilteredMergeFields(
                  _.filter(mergeFields, (o) => o.toLowerCase().includes(e.target.value)),
                )

                setSearchTerm(e.target.value)
              }}
              placeholder="Search"
              ref={searchInputRef}
              type="text"
              value={searchTerm}
            />

            <div className="mx-8 mt-2 flex flex-col overflow-y-scroll">
              {_.map(filteredMergeFields, (f) => (
                <button
                  className="group flex cursor-pointer flex-row"
                  key={`${f}`}
                  onClick={() => {
                    navigator.clipboard.writeText(`{{ ${f} }}`)
                    handleSuccess('Merge field copied to clipboard!')
                  }}
                  type="button"
                >
                  <span className="group-hover:text-purple">{f}</span>

                  <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-purple" />
                </button>
              ))}
            </div>
          </div>
        }
        open={showMergeFieldModal}
        title="Copy Merge Field"
      />

      {showCustomPopupModal && (
        <CustomPopupModal
          availableCustomFields={event?.availableCustomFields}
          customPopup={editCustomPopup}
          eventId={event.id}
          kioskConfigurationId={kioskConfiguration.id}
          mergeFields={mergeFields}
          onClose={() => {
            setShowCustomPopupModal(false)
            setEditCustomPopup(null)
            getCustomPopupMessages()
          }}
        />
      )}
    </div>
  )
}

Popups.propTypes = {
  event: PropTypes.object.isRequired,
  kioskConfiguration: PropTypes.object.isRequired,
  setKioskConfiguration: PropTypes.func.isRequired,
}

export { Popups }
