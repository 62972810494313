import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

// Components
import { Alert } from '../../components/Alert'
import { AuthGradient } from '../../components/AuthGradient'
import { Button } from '../../components/Button'
import { TextInput } from '../../components/TextInput'

// Images
import Badger from '../../assets/images/badgr_text_logo.svg'
import EventstackLogo from '../../assets/images/eventstack_logo.svg'
import Fetch from '../../assets/images/fetch_text_logo.svg'
import SMS from '../../assets/images/sms.svg'

// Utils and messages
import useFlashMessage from '../../hooks/FlashMessage'

// Service
import { forgotPassword } from '../../services/user.service'

/**
 *
 * PasswordResetRequest
 *
 */
const PasswordResetRequest = () => {
  // Context
  const [searchParams] = useSearchParams()

  // State
  const [loading, setLoading] = useState(false)

  const redirectTo = searchParams.get('redirect')

  // Messages
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const { message: success, showMessage: showSuccess } = useFlashMessage(null)

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm()

  /**
   * Handles form submission
   * - Clears form if successful
   * @param {object} data
   */
  const onSubmit = async (data) =>
    forgotPassword(data, redirectTo, showError, setLoading, (m) => {
      showSuccess(m)

      // Reset form
      reset()
    })

  return (
    <div className="relative flex h-full w-full flex-col items-center overflow-hidden bg-[#181823] py-12 text-white sm:justify-between">
      <AuthGradient />

      <div className="w-64 self-center sm:ml-8 sm:w-[184px] sm:self-start">
        <img src={EventstackLogo} alt="" />
      </div>

      <div className="z-1 relative mt-12 h-full max-w-[675px] sm:mx-auto sm:mt-0 sm:h-auto sm:w-2/3 sm:self-center">
        <h2 className="text-center text-3xl font-bold">Forgot your password?</h2>

        <p className="mb-4 mt-2 text-center text-sm text-white">
          Or{' '}
          <a
            href={`/login${redirectTo ? `?redirect=${redirectTo}` : ''}`}
            className="font-medium text-purple hover:text-purple"
          >
            Log In
          </a>
        </p>

        {/* Status Messages */}
        {success && <Alert className="mb-6" message={success} title="Success" type="success" />}
        {error && <Alert className="mb-6" message={error} title="Error" type="error" />}

        <form
          id="password-reset-request-form"
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 space-y-8 sm:mt-0 sm:space-y-6"
        >
          <TextInput
            className="flex h-12 w-full rounded-md border-gray bg-gray py-2.5 pl-14 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple sm:h-[60px] sm:pl-24"
            data-testid="email"
            error={errors.email && 'This field is required'}
            autoComplete="email"
            id="email"
            icon={
              <div className="flex h-full w-10 items-center justify-center border-r border-gray-700 px-2 sm:w-[72px] sm:px-4">
                <img src={SMS} alt="email" className="h-5 stroke-purple" />
              </div>
            }
            inputStyles="bg-transparent text-white text-md sm:text-base"
            name="email"
            placeholder="Email"
            {...register('email', { required: true })}
          />

          {/* Submit */}
          <Button
            className="text-md flex h-12 items-center rounded-md border-purple sm:h-[60px] sm:text-base"
            fullWidth
            label="Submit"
            loading={loading}
          />
        </form>
      </div>

      <div className="flex items-center justify-center gap-x-6 text-lg font-bold sm:justify-start">
        <img src={Fetch} alt="" className="h-[39px]" /> +{' '}
        <img src={Badger} alt="" className="h-[30px]" />
      </div>
    </div>
  )
}

export default PasswordResetRequest
