/* eslint-disable indent */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { SparklesIcon } from '@heroicons/react/24/solid'
import { Controller, useForm } from 'react-hook-form'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// Images
import { ChevronDownIcon, ChevronUpIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'

// Components
import { Button } from '../../components/Button'
import { EditIcon } from '../../components/EditIcon'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { PlusIcon } from '../../components/PlusIcon'
import { Select } from '../../components/Select'
import { Toggle } from '../../components/Toggle'

// Helpers
import { CUSTOM_BLOCKS, PRESET_BLOCKS } from './helpers'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Utils & Services
import { toast } from '../../utils/helpers'
import { addBadgeImage } from '../../services/badges.service'

/**
 *
 * BadgeContentEditor
 *
 */
const BadgeContentEditor = ({
  addElementToPanel,
  handleChangeStockSize,
  handlePanelUpdates,
  enableMirrorContent,
  layers: panelLayers,
  mergeFields,
  moveLayer,
  numOfPanels,
  panelOptions,
  selectedPanel,
  setSelectedPanel,
  showGrid,
  setShowGrid,
  stockOptions,
  stockSize,
  toggleLayerSettings,
  updateLayerVisibility,
}) => {
  // Context
  const { event } = useContext(NavigationStoreContext)

  // State
  const [panelViewOpen, setPanelViewOpen] = useState(true)
  const [layersOpen, setLayersOpen] = useState(true)
  const [blocksOpen, setBlocksOpen] = useState(true)
  const [activeTab, setActiveTab] = useState('Preset')
  const [layers, setLayers] = useState(panelLayers)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [customMergeField, setCustomMergeField] = useState(null)
  const [showFileUploader, setShowFileUploader] = useState(false)
  const [showChangePanelModal, setShowChangePanelModal] = useState(false)
  const [updatedStockSize, setUpdatedStockSize] = useState(stockSize)

  const handleErrors = (message) => toast(message, 'error')

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      mergeField: null,
    },
  })

  useEffect(() => {
    setLayers(panelLayers)
  }, [panelLayers])

  const configureMergeFieldOptions = useMemo(
    () =>
      _.concat(
        [
          { id: 'firstName', label: 'firstName' },
          { id: 'lastName', label: 'lastName' },
        ],
        _.map(_.keys(mergeFields), (k) => ({ id: k, label: k })),
      ),
    [mergeFields],
  )

  /**
   * Handles the drag and drop reordering of the layers.
   * @param {object} result
   */
  const onDragEnd = async (result) => {
    // Dropped outside the list or tried to move the base layer
    if (
      !result.destination ||
      result.destination.index === 0 ||
      result.destination.index === result.source.index
    ) {
      return
    }

    // Reorder the layers within the badge
    moveLayer(result.source.index, result.destination.index)

    // Update local state
    const updatedLayers = [...layers]
    const [removed] = updatedLayers.splice(result.source.index, 1)
    updatedLayers.splice(result.destination.index, 0, removed)

    setLayers(updatedLayers)
  }

  /**
   * Creates a temporary image element to get the image dimensions, then calculates and returns the aspect ratio.
   * @param {string} url The URL of the image
   * @returns {Promise} The aspect ratio of the image
   */
  const getImageDimensions = async (url) => {
    const img = new Image()
    img.src = url
    return new Promise((resolve, reject) => {
      img.onload = () => {
        resolve({ aspectRatio: img.width / img.height })
      }
      img.onerror = (err) => reject(err)
    })
  }

  /**
   * Renders a list of block elements.
   * @param {Array} blocks
   */
  const renderBlocks = (blocks) =>
    blocks.map((block) => (
      <>
        <button
          key={block.name}
          className="mb-3 flex w-full cursor-pointer items-center gap-2 rounded-xl border-2 border-dashed border-white bg-white/25 px-2.5 py-4 font-medium"
          onClick={() => {
            if (block.className === 'Image' && block.name !== 'QR Code') {
              setShowFileUploader(true)
            } else if (block.name === 'Merge Field') {
              setCustomMergeField(block)
            } else {
              addElementToPanel(block, 0)
            }
          }}
          type="button"
        >
          <span className="flex w-[20px] justify-center">{block.icon}</span>
          <span className="text-sm">{block.name}</span>
        </button>
        {block.name === 'Image' && showFileUploader && (
          <FileUploader
            whiteBackground
            acceptedFileTypes={['image/*']}
            allowResize
            allowRevert
            handleUploadToServer={async (file) => {
              const badgeImage = await addBadgeImage(
                event.id,
                { url: file.url },
                handleErrors,
                () => {},
                () => {},
              )

              if (badgeImage) {
                const { aspectRatio } = await getImageDimensions(badgeImage.signedUrl)
                const scaledHeight = 300 / aspectRatio

                const imageElement = {
                  attrs: {
                    src: badgeImage.signedUrl,
                    badgeImagePath: `/events/${event.id}/badge-images/${badgeImage.id}`,
                    height: scaledHeight,
                    width: 300,
                  },
                  className: 'Image',
                }

                const updatedBlock = { ...block }
                updatedBlock.element = imageElement

                setShowFileUploader(false)
                addElementToPanel(updatedBlock, 0)
              }
            }}
            id="badgeImageUrl"
            imageCropAspectRatio="1:1"
            maxFileSize="750KB"
            type="gcp"
          />
        )}
      </>
    ))

  /**
   * Renders a collapsible section with specified `label` and `content`.
   * @param {string} label
   * @param {func} onClick
   * @param {boolean} active
   * @param {element} content
   */
  const renderCollapsible = (label, onClick, active, content) => (
    <div>
      <button
        type="button"
        onClick={onClick}
        className="my-5 flex w-full cursor-pointer items-center justify-between font-bold uppercase"
      >
        <span className="text-sm">{label}</span>
        {active ? (
          <ChevronUpIcon className="h-5 fill-white" />
        ) : (
          <ChevronDownIcon className="h-5 fill-white" />
        )}
      </button>

      {active && <div>{content}</div>}
    </div>
  )

  return (
    <div className="h-full max-h-[850px] w-[30%] overflow-y-auto rounded-xl bg-darkBlue px-5 pb-2 text-white">
      {renderCollapsible(
        'Panel view',
        () => setPanelViewOpen(!panelViewOpen),
        panelViewOpen,
        <div className="space-y-2 pl-2">
          <Button
            label="Change Panel Size"
            onClick={() => setShowChangePanelModal(true)}
            fullWidth
          />

          <div className="space-y-2">
            <span className="text-sm font-semibold">Select Panel</span>
            <Select
              className="border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="panel"
              fullWidth
              id="panel"
              nunito
              onChange={(o) => {
                if (o.id === -1) {
                  // Add a new panel
                  handlePanelUpdates('add')
                } else {
                  setSelectedPanel(o.id)
                }
              }}
              options={panelOptions}
              style={{ flex: true, width: '100%' }}
              value={_.find(panelOptions, { id: selectedPanel })}
            />

            {numOfPanels > 1 && (
              <Toggle
                inter
                label={<span className="text-sm text-white">Same Content Front & Back</span>}
                onChange={() => {
                  if (enableMirrorContent) {
                    handlePanelUpdates('mirror')
                  } else {
                    setShowConfirmModal(true)
                  }
                }}
                checked={enableMirrorContent}
              />
            )}
          </div>

          <Toggle
            inter
            label={<span className="text-sm text-white">Show Grid Lines</span>}
            onChange={() => {
              setShowGrid(!showGrid)
            }}
            checked={showGrid}
          />
        </div>,
      )}

      {layers &&
        renderCollapsible(
          'Layers',
          () => setLayersOpen(!layersOpen),
          layersOpen,
          <div className="flex flex-col pl-2">
            <div
              key="base"
              className="pointer-events-none flex w-full items-center justify-between"
              role="button"
              title="Toggle layer settings"
            >
              <div className="flex flex-row gap-2">
                <span className="text-sm">Base</span>
              </div>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="flex flex-col"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      height: (layers.length - 1) * 28,
                    }}
                  >
                    {_.drop(layers, 1).map((layer, i) => {
                      let layerName = layer.attrs.id === 'base' ? 'Base' : layer.attrs.name
                      if (layer.attrs.id !== 'base' && !layer.attrs.name)
                        layerName = `Layer ${layer.attrs.index}`
                      return (
                        <Draggable
                          key={layer.attrs.id}
                          draggableId={layer.attrs.id}
                          index={i}
                          isDragDisabled
                        >
                          {(p, s) => (
                            <>
                              {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */}
                              <div
                                key={layer.attrs.id}
                                className="flex h-7 w-full items-center justify-between py-1.5"
                                onClick={() => toggleLayerSettings(layer)}
                                role="button"
                                title="Toggle layer settings"
                                ref={p.innerRef}
                                style={{
                                  ...p.draggableProps.style,
                                }}
                                {...p.draggableProps}
                                {...p.dragHandleProps}
                              >
                                <div className="flex flex-row gap-2">
                                  <span
                                    className={mergeClassNames(
                                      'text-sm',
                                      s.isDragging && 'text-purple',
                                    )}
                                  >
                                    {layerName}
                                  </span>

                                  {layer.attrs.visibility &&
                                    layer.attrs.visibility !== 'always' && (
                                      <span className="font-white rounded-full px-1.5 py-0.5 text-xs">
                                        {_.capitalize(layer.attrs.visibility.split(':')[2])}
                                      </span>
                                    )}
                                </div>

                                {/* Display visibility control */}
                                <button
                                  className="px-1 py-0.5"
                                  onClick={(e) => {
                                    e.stopPropagation()

                                    updateLayerVisibility({
                                      ...layer,
                                      attrs: { ...layer.attrs, visible: !layer.attrs.visible },
                                    })
                                  }}
                                  title="Toggle layer visibility"
                                  type="button"
                                >
                                  {layer.attrs.visible ? (
                                    <EyeIcon className="h-5 w-5" />
                                  ) : (
                                    <EyeSlashIcon className="h-5 w-5" />
                                  )}
                                </button>
                              </div>

                              {provided.placeholder}
                            </>
                          )}
                        </Draggable>
                      )
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {layers.length < 11 && (
              <button
                type="button"
                onClick={() => addElementToPanel({ className: 'Layer' })}
                className="mt-1 flex items-center fill-white hover:fill-purple hover:text-purple"
              >
                <PlusIcon className="h-5 fill-inherit" />
                <span className="text-sm">Add Layer</span>
              </button>
            )}
          </div>,
        )}

      {renderCollapsible(
        'Blocks',
        () => setBlocksOpen(!blocksOpen),
        blocksOpen,
        <div>
          <div className="mb-3">
            <button
              type="button"
              className={mergeClassNames(
                'mr-4 border-b-2 border-transparent hover:border-purple hover:text-purple',
                activeTab === 'Preset' && 'border-purple text-purple',
              )}
              onClick={() => setActiveTab('Preset')}
            >
              <span className="text-sm">Preset</span>
            </button>

            <button
              type="button"
              className={mergeClassNames(
                'mr-4 border-b-2 border-transparent hover:border-purple hover:text-purple',
                activeTab === 'Custom' && 'border-purple text-purple ',
              )}
              onClick={() => setActiveTab('Custom')}
            >
              <span className="text-sm">Custom</span>
            </button>
          </div>

          {activeTab === 'Preset' && <div>{renderBlocks(PRESET_BLOCKS)}</div>}
          {activeTab === 'Custom' && (
            <div>
              {renderBlocks(
                mergeFields === null ? _.without(CUSTOM_BLOCKS, CUSTOM_BLOCKS[1]) : CUSTOM_BLOCKS,
              )}
            </div>
          )}
        </div>,
      )}

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => setShowConfirmModal(false),
          },
          {
            type: 'submit',
            label: 'Confirm',
            onClick: () => {
              handlePanelUpdates('mirror')
              setShowConfirmModal(false)
            },
            background: 'bg-orange',
          },
        ]}
        icon={<ExclamationTriangleIcon className="h-8 text-white" />}
        iconBackground="bg-orange"
        content={
          <div className="mt-3 flex text-center sm:mt-5">
            <span className="text-sm">
              This will duplicate the content from the front panel to the back, and the back panel
              will not be accessible.
            </span>
          </div>
        }
        open={showConfirmModal}
        title="Are you sure?"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => setCustomMergeField(false),
          },
          {
            type: 'submit',
            label: 'Add to Badge',
            onClick: handleSubmit((data) => {
              const updatedElement = {
                ...customMergeField,
                element: {
                  ...customMergeField.element,
                  attrs: {
                    ...customMergeField.element.attrs,
                    text: `{{ ${data.mergeField.id} }}`,
                    accessor: data.mergeField.id,
                  },
                },
              }

              addElementToPanel(updatedElement, 0)
              reset()
              setCustomMergeField(null, 0)
            }),
          },
        ]}
        icon={<SparklesIcon className="h-8 fill-white" />}
        content={
          <div className="mt-3 flex text-center sm:mt-5">
            {customMergeField && (
              <Controller
                control={control}
                name="mergeField"
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="mergeField"
                    error={errors.mergeField && 'This field is required'}
                    fullWidth
                    id="mergeField"
                    nunito
                    onChange={onChange}
                    options={configureMergeFieldOptions}
                    label="Select Merge Field"
                    placeholder="Select Merge Field"
                    search
                    style={{ flex: true, width: '100%' }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
            )}
          </div>
        }
        open={!!customMergeField}
        title="Add a Custom Merge Field"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => setShowChangePanelModal(false),
          },
          {
            type: 'submit',
            label: 'Confirm',
            onClick: () => {
              handleChangeStockSize(_.find(stockOptions, { id: updatedStockSize }))
              setShowChangePanelModal(false)
            },
          },
        ]}
        icon={<EditIcon className="h-5 fill-white stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <span className="text-sm">
              This will affect all panels for your badge. If the size if smaller than the current
              size, elements will be repositioned to fit the new size.
            </span>

            <Select
              className="border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="badge-size"
              fullWidth
              id="panel"
              nunito
              onChange={(o) => setUpdatedStockSize(o.id)}
              options={stockOptions}
              style={{ flex: true, width: '100%' }}
              value={_.find(stockOptions, { id: updatedStockSize })}
            />
          </div>
        }
        open={showChangePanelModal}
        title="Change Panel Size"
      />
    </div>
  )
}

BadgeContentEditor.defaultProps = {
  mergeFields: [],
}

BadgeContentEditor.propTypes = {
  addElementToPanel: PropTypes.func.isRequired,
  handleChangeStockSize: PropTypes.func.isRequired,
  handlePanelUpdates: PropTypes.func.isRequired,
  enableMirrorContent: PropTypes.bool.isRequired,
  layers: PropTypes.array.isRequired,
  mergeFields: PropTypes.object,
  moveLayer: PropTypes.func.isRequired,
  numOfPanels: PropTypes.number.isRequired,
  panelOptions: PropTypes.array.isRequired,
  showGrid: PropTypes.bool.isRequired,
  selectedPanel: PropTypes.number.isRequired,
  setSelectedPanel: PropTypes.func.isRequired,
  setShowGrid: PropTypes.func.isRequired,
  stockOptions: PropTypes.array.isRequired,
  stockSize: PropTypes.object.isRequired,
  toggleLayerSettings: PropTypes.func.isRequired,
  updateLayerVisibility: PropTypes.func.isRequired,
}

export default BadgeContentEditor
