import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// Components
import { ColorPicker } from '../../components/ColorPicker'
import { FileUploader } from '../../components/FileUploader'
import { MiniKiosk } from './MiniKiosk'
import { Select } from '../../components/Select'
import { Toggle } from '../../components/Toggle'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { getGlobalCustomFonts, getGoogleFonts } from '../../services/settings.service'
import { updateKioskConfiguration } from '../../services/kiosks.service'

// Utils & Style
import { toast } from '../../utils/helpers'

const DesignSettings = ({ event, kioskConfiguration, setKioskConfiguration }) => {
  // Context
  const { setEvent } = useContext(NavigationStoreContext)

  // State
  const [fonts, setFonts] = useState([])
  const [loadingFonts, setLoadingFonts] = useState(false)
  const [uploadHeader, setUploadHeader] = useState(!event.kioskConfiguration?.signedHeaderImage)
  const [uploadFooter, setUploadFooter] = useState(!event.kioskConfiguration?.signedFooterImage)

  useEffect(() => {
    const getGlobalFonts = async () => {
      setLoadingFonts(true)

      let options = []
      // Pull Google Fonts
      const data = await getGoogleFonts(
        () => {},
        () => {},
      )
      if (data) {
        const filteredFonts = _.map(
          _.filter(
            data.items,
            (i) => i.variants.includes('regular') && i.variants.includes('italic'),
          ),
          (f) => ({
            id: f.family,
            label: f.family,
            ...f,
          }),
        )

        options = filteredFonts
      }

      // Pull Custom Fonts
      const globalFonts = await getGlobalCustomFonts(
        () => {},
        () => {},
      )

      if (globalFonts) {
        options = [
          ..._.map(globalFonts, (f) => ({ id: f.id, label: f.name, url: f.signedUrl })),
          ...options,
        ]
      }

      setLoadingFonts(false)
      setFonts(options)
    }

    getGlobalFonts()
  }, [])

  useEffect(() => {
    setUploadHeader(!kioskConfiguration.signedHeaderImage)
    setUploadFooter(!kioskConfiguration.signedFooterImage)
  }, [kioskConfiguration])

  /**
   * Saves kiosk configuration to BE
   * @param {object} data
   */
  const saveConfig = async (data) => {
    const response = await updateKioskConfiguration(
      event.id,
      data,
      () => {},
      () => {},
      handleSuccess,
    )

    setEvent({
      ...event,
      kioskConfiguration: response,
    })
    setKioskConfiguration(response)
  }

  /**
   * Debounce saving the kiosk configuration to BE
   */
  const saveUpdatedConfig = useCallback(_.debounce(saveConfig, 500), [kioskConfiguration])

  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Finds the configured font. This can either be a Google Font or a custom font.
   */
  const selectedFont = useMemo(() => {
    if (kioskConfiguration.googleFont) {
      return fonts.find((f) => f.family === kioskConfiguration.googleFont)
    }

    if (kioskConfiguration.customFont) {
      return fonts.find((f) => f.id === kioskConfiguration.customFont)
    }

    return null
  }, [fonts, kioskConfiguration])

  return (
    <div className="flex h-full flex-col justify-between gap-10 overflow-y-auto px-8 py-6 xl:flex-row">
      <div className="h-full flex-col gap-4 md:basis-2/5">
        <div className="text-2xl font-bold">Brand Settings</div>
        <div className="flex flex-row items-center space-x-4">
          <div className="flex flex-col gap-1">
            <div className="text-md font-medium text-gray-800">Background Color</div>
            <div className="text-xs text-gray-600">
              Set the background color of your attendee kiosk
            </div>
          </div>

          <ColorPicker
            color={kioskConfiguration.backgroundColor}
            disabled={kioskConfiguration.useDefaultBrand}
            id="backgroundPicker"
            onChange={(color) =>
              saveUpdatedConfig({ id: kioskConfiguration.id, backgroundColor: color.hex })
            }
          />
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-md font-medium text-gray-800">Font</div>
          <div className="text-xs text-gray-600">
            Choose the font style and color for your attendee kiosk
          </div>
          <div className="flex items-center gap-2">
            <Select
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              dataTestId="fontFamily"
              disabled={loadingFonts || kioskConfiguration.useDefaultBrand}
              fullWidth
              id="fontFamily"
              name="fontFamily"
              nunito
              onChange={(newFont) => {
                if (newFont.url) {
                  saveUpdatedConfig({
                    id: kioskConfiguration.id,
                    customFont: newFont.id,
                    googleFont: null,
                  })
                } else {
                  saveUpdatedConfig({
                    id: kioskConfiguration.id,
                    googleFont: newFont.family,
                    customFont: null,
                  })
                }
              }}
              options={fonts}
              search
              style={{ flex: true, width: '100%' }}
              value={selectedFont}
            />

            <ColorPicker
              color={kioskConfiguration.fontColor}
              disabled={kioskConfiguration.useDefaultBrand}
              onChange={(color) =>
                saveUpdatedConfig({ id: kioskConfiguration.id, fontColor: color.hex })
              }
            />
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-md font-medium text-gray-800">Header Image</div>
          <div className="text-xs text-gray-600">
            Upload header banner with event logo and branding (1200px x 144px)
          </div>

          {kioskConfiguration.signedHeaderImage && !uploadHeader ? (
            <button
              className="mt-2 self-start text-sm text-purple disabled:cursor-not-allowed"
              disabled={kioskConfiguration.useDefaultBrand}
              onClick={() => setUploadHeader(true)}
              type="button"
            >
              Replace
            </button>
          ) : (
            uploadHeader && (
              <FileUploader
                acceptedFileTypes={['image/*']}
                allowResize
                allowRevert
                disabled={kioskConfiguration.useDefaultBrand}
                handleUploadToServer={(file) => {
                  setUploadHeader(false)
                  saveUpdatedConfig({ id: kioskConfiguration.id, headerImage: file.url })
                }}
                id="headerImage"
                imageCropAspectRatio="1:1"
                maxFileSize="390KB"
                type="gcp"
              />
            )
          )}
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-md font-medium text-gray-800">Footer Image</div>
          <div className="text-xs text-gray-600">
            <div>Upload footer banner with event logo and branding (1200 x 144)</div>
          </div>
          {kioskConfiguration.signedFooterImage && !uploadFooter ? (
            <button
              className="mt-2 self-start text-sm text-purple disabled:cursor-not-allowed"
              disabled={kioskConfiguration.useDefaultBrand}
              onClick={() => setUploadFooter(true)}
              type="button"
            >
              Replace
            </button>
          ) : (
            uploadFooter && (
              <FileUploader
                acceptedFileTypes={['image/*']}
                allowResize
                allowRevert
                disabled={kioskConfiguration.useDefaultBrand}
                handleUploadToServer={async (file) => {
                  setUploadFooter(false)
                  saveUpdatedConfig({ id: kioskConfiguration.id, footerImage: file.url })
                }}
                id="footerImage"
                imageCropAspectRatio="1:1"
                maxFileSize="390KB"
                type="gcp"
              />
            )
          )}
        </div>
      </div>

      <div className="h-full md:basis-3/5">
        {kioskConfiguration && <MiniKiosk configuration={kioskConfiguration} />}
        <div className="flex justify-end">
          <Toggle
            className="mt-4 self-end"
            id="useDefaultBrand"
            label="Use Default Brand Settings"
            name="useDefaultBranding"
            onChange={(checked) =>
              saveUpdatedConfig({ id: kioskConfiguration.id, useDefaultBrand: checked })
            }
            checked={kioskConfiguration.useDefaultBrand}
          />
        </div>
      </div>
    </div>
  )
}

DesignSettings.propTypes = {
  event: PropTypes.object.isRequired,
  kioskConfiguration: PropTypes.object.isRequired,
  setKioskConfiguration: PropTypes.func.isRequired,
}

export { DesignSettings }
