import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { v4 as uuid4 } from 'uuid'

// Components
import { AddUserIcon } from '../../components/AddUserIcon'
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import { CircleCheckIconSolid } from '../../components/CircleCheckIconSolid'
import { DataTable } from '../../components/DataTable'
import { EditAttendeeModal } from '../../components/EditAttendeeModal'
import { PrinterIcon } from '../../components/PrinterIcon'
import { QRIcon } from '../../components/QRIcon'
import { TextInput } from '../../components/TextInput'
import QuickBadgeModal from './QuickBadgeModal'

// Store
import { KioskStoreContext } from '../../stores/KioskStore'

// Services & Utils
import { convertIncomingData } from '../../services/transforms'
import { instance } from '../../services/firestore/instance'
import { addBadgePrintLog, updateAttendee } from '../../services/firestore/firestore.service'
import { GDPR_COUNTRIES } from '../../utils/constants'
import colors from '../../utils/colors'
import { Select } from '../../components/Select'

const SEARCH_BY_OPTIONS = [
  {
    label: 'First Name',
    id: 'first_name_lower',
  },
  {
    label: 'Last Name',
    id: 'last_name_lower',
  },
  {
    label: 'Email',
    id: 'email_lower',
  },
  {
    label: 'Company',
    id: 'company_name_lower',
  },
]

const CATEGORY_COLORS = [
  { background: colors.purple.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.teal.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.blue.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.purple[900], label: colors.white.DEFAULT },
  { background: colors.teal[900], label: colors.white.DEFAULT },
  { background: colors.blue[900], label: colors.white.DEFAULT },
  { background: colors.purple.light, label: colors.gray.dark },
  { background: colors.teal.light, label: colors.gray.dark },
  { background: colors.blue.light, label: colors.gray.dark },
  { background: colors.purple.dark, label: colors.white.DEFAULT },
  { background: colors.teal.dark, label: colors.white.DEFAULT },
  { background: colors.blue.dark, label: colors.white.DEFAULT },
]

const getCategoryFromId = (id, categories) =>
  _.find(_.keys(categories), (k) => categories[k].category_id === id)

const NameCell = ({ attendee, activeTab }) => {
  let status = <CircleCheckIconSolid className="h-4 fill-purple" />
  let tooltip = 'Confirmed'
  let background = 'bg-purple'

  if (attendee.customData && attendee.customData?.registrationstatus === 'In-progress') {
    status = <ExclamationTriangleIcon className="h-4 fill-orange" />
    tooltip = 'In Progress'
    background = 'bg-orange'
  } else if (
    attendee.customData?.registrationstatus !== 'Confirmed' &&
    attendee.customData?.registrationstatus !== 'Attended'
  ) {
    status = <XCircleIcon className="h-4 fill-red" />
    tooltip = 'Registration Error'
    background = 'bg-red'
  } else if (attendee.customData && attendee.customData?.balance_due > 0) {
    status = <ExclamationTriangleIcon className="h-4 fill-blue" />
    tooltip = 'Balance Due'
    background = 'bg-blue'
  } else if (
    !attendee.isCheckedIn &&
    attendee.customData &&
    GDPR_COUNTRIES.includes(attendee.customData?.country)
  ) {
    status = <ExclamationTriangleIcon className="h-4 fill-blue" />
    tooltip = 'GDPR'
    background = 'bg-blue'
  }

  return (
    <div className="flex flex-col gap-1" key={`attendee:${attendee.id}`}>
      <div className="flex flex-row gap-1">
        {activeTab === 'attendee' && (
          <div className="has-tooltip">
            <div className="tooltip -ml-2 -mt-8 flex rounded shadow-lg">
              <span
                className={mergeClassNames(
                  'rounded-lg px-2 py-1 text-white shadow-lg',
                  background,
                )}
              >
                {tooltip}
              </span>
            </div>
            {status}
          </div>
        )}
        <span className="font-medium text-gray-dark">
          {attendee.firstName} {attendee.lastName}
        </span>
      </div>
      {activeTab === 'attendee' && <span className="truncate">{attendee.email}</span>}
    </div>
  )
}

NameCell.propTypes = {
  attendee: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
}

const Staff = observer(
  ({
    configuration,
    handleBulkPrint,
    registrationSyncData,
    registrationSyncEnabled,
    setAttendeeToCheckIn,
  }) => {
    // Context
    const { eventId, location, name } = useContext(KioskStoreContext)

    // State
    const [searchBy, setSearchBy] = useState('last_name_lower')
    const [searchTerm, setSearchTerm] = useState('')
    const [attendeeQuery, setAttendeeQuery] = useState(
      query(collection(instance, `events/${eventId}/kiosk-attendees`)),
    )
    const [quickBadgeQuery, setQuickBadgeQuery] = useState(
      query(collection(instance, `events/${eventId}/kiosk-quick-badges`)),
    )

    // Attendees
    const [attendees, setAttendees] = useState([])
    const [selectedAttendees, setSelectedAttendees] = useState([])
    const [activeTab, setActiveTab] = useState('attendee')
    const [attendeeLastUpdated, setAttendeeLastUpdated] = useState('')
    const [attendeeToEdit, setAttendeeToEdit] = useState(null)

    // Quick Badges
    const [quickBadges, setQuickBadges] = useState([])
    const [selectedQuickBadges, setSelectedQuickBadges] = useState([])
    const [quickBadgeLastUpdated, setQuickBadgeLastUpdated] = useState('')
    const [showQuickBadgeModal, setShowQuickBadgeModal] = useState(false)
    const [quickBadgeDuplicate, setQuickBadgeDuplicate] = useState(null)

    // Ref
    const searchInputRef = useRef()

    /**
     * Sets up the snapshot listener for the attendee queries.
     * - This will fire every time the attendee query changes.
     */
    useEffect(() => {
      const unsubscribeAttendeeSnapshot = onSnapshot(attendeeQuery, (snapshot) => {
        const filteredResults = snapshot.docs.map((d) => convertIncomingData(d.data()))
        setAttendees(filteredResults)
        setAttendeeLastUpdated(new Date())
      })

      return () => {
        if (unsubscribeAttendeeSnapshot) unsubscribeAttendeeSnapshot()
      }
    }, [attendeeQuery])

    /**
     * Sets up the snapshot listener for the quick badge queries.
     * - This will fire every time the quick badge query changes.
     */
    useEffect(() => {
      const unsubscribeQuickBadgeSnapshot = onSnapshot(quickBadgeQuery, (snapshot) => {
        const filteredResults = snapshot.docs.map((d) => convertIncomingData(d.data()))
        setQuickBadges(filteredResults)
        setQuickBadgeLastUpdated(new Date())
      })

      return () => {
        if (unsubscribeQuickBadgeSnapshot) unsubscribeQuickBadgeSnapshot()
      }
    }, [quickBadgeQuery])

    /**
     * Sets up the query for the attendees.
     * - Default to ordering by first name.
     * - Only updates when the search term changes and the active tab is 'attendee'.
     */
    useEffect(() => {
      if (activeTab === 'attendee') {
        const options = []
        if (searchTerm) {
          options.push(where(searchBy, '>=', searchTerm.toLowerCase()))
          options.push(where(searchBy, '<=', `${searchTerm.toLowerCase()}~`))
        }

        const searchQuery = query(
          collection(instance, `events/${eventId}/kiosk-attendees`),
          ...options,
        )

        setAttendeeQuery(searchQuery)
      }
    }, [searchTerm, activeTab, searchBy])

    /**
     * Sets up the query for the quick badges.
     * - Default to ordering by first name.
     * - Only updates when the search term changes and the active tab is 'quickBadges'.
     */
    useEffect(() => {
      if (activeTab === 'quickBadges') {
        const options = []
        if (searchTerm) {
          options.push(where(searchBy, '>=', searchTerm.toLowerCase()))
          options.push(where(searchBy, '<=', `${searchTerm.toLowerCase()}~`))
        }

        const searchQuery = query(
          collection(instance, `events/${eventId}/quick-badges`),
          ...options,
        )

        setQuickBadgeQuery(searchQuery)
      }
    }, [searchTerm, activeTab, searchBy])

    const configureBulkPrintLabel = () => {
      if (activeTab === 'attendee') {
        return `Bulk Print Badge ${
          selectedAttendees.length > 1 ? `(${selectedAttendees.length})` : ''
        }`
      }

      return `Bulk Print Badge ${
        selectedQuickBadges.length > 1 ? `(${selectedQuickBadges.length})` : ''
      }`
    }

    /**
     * Configures the category tag to display for the attendee using the same colors as the KPIs.
     * @param {string} category
     */
    const configureCategoryTag = (category) => {
      const index = _.findIndex(
        _.sortBy(_.keys(registrationSyncData.attendeeCategories)),
        (k) => k === category,
      )

      let displayColors
      if (index === -1) {
        displayColors = { background: colors.gray.light, label: colors.gray.dark }
      } else
        displayColors = CATEGORY_COLORS[index] || {
          background: colors.gray.light,
          label: colors.gray.dark,
        }

      return (
        <span
          className="rounded-full px-2.5 py-1 text-xs font-medium"
          style={{ backgroundColor: displayColors.background, color: displayColors.label }}
        >
          {_.capitalize(category) || 'No Category'}
        </span>
      )
    }

    const COLUMNS = useMemo(
      () => [
        {
          id: 'select',
          width: '48px',
          name: (
            <div className="flex flex-row">
              <CheckBox
                className="ml-2"
                onChange={() => {
                  // If viewing attendees, select all attendees
                  if (
                    activeTab === 'attendee' &&
                    (selectedAttendees?.length === 0 ||
                      selectedAttendees?.length < attendees?.length)
                  ) {
                    setSelectedAttendees(attendees)
                  }
                  // Otherwise, clear all selected attendees
                  else if (activeTab === 'attendee') {
                    setSelectedAttendees([])
                  }
                  // If viewing quick badges, select all quick badges
                  else if (
                    selectedQuickBadges?.length === 0 ||
                    selectedQuickBadges?.length < quickBadges?.length
                  ) {
                    setSelectedQuickBadges(quickBadges)
                  }
                  // Otherwise, clear all selected quick badges
                  else {
                    setSelectedQuickBadges([])
                  }
                }}
                value={selectedAttendees?.length === attendees?.length}
              />
            </div>
          ),
          selector: (row) => row.id,
          cell: (row) => {
            let selected = null
            if (activeTab === 'attendee') {
              selected = _.find(selectedAttendees, (a) => a.id === row.id)
            } else {
              selected = _.find(selectedQuickBadges, (a) => a.id === row.id)
            }

            return (
              <div className="">
                <CheckBox
                  className="ml-2"
                  onChange={() => {
                    if (activeTab === 'attendee' && selected) {
                      setSelectedAttendees(selectedAttendees.filter((a) => a.id !== row.id))
                    } else if (activeTab === 'attendee') {
                      setSelectedAttendees([...selectedAttendees, row])
                    } else if (selected) {
                      setSelectedQuickBadges(selectedQuickBadges.filter((a) => a.id !== row.id))
                    } else {
                      setSelectedQuickBadges([...selectedQuickBadges, row])
                    }
                  }}
                  value={selected}
                />
              </div>
            )
          },
          sortable: false,
        },
        {
          id: 'print',
          cell: (row) => (
            <Button
              background={row.isCheckedIn || activeTab === 'quickBadges' ? 'bg-teal' : 'bg-purple'}
              disabled={
                row.customData &&
                row.customData?.registrationstatus !== 'Confirmed' &&
                row.customData?.registrationstatus !== 'Attended' &&
                activeTab !== 'quickBadges'
              }
              label={row.isCheckedIn || activeTab === 'quickBadges' ? 'Re-Print' : 'Print Badge'}
              onClick={() => setAttendeeToCheckIn(row)}
            />
          ),
          width: '150px',
          center: true,
        },
        {
          id: 'name',
          name: 'Name',
          selector: (row) => row.firstName,
          cell: (row) => <NameCell attendee={row} activeTab={activeTab} />,
          sortable: true,
          grow: 1,
        },
        {
          id: 'companyAndTitle',
          name: 'Company/Title',
          selector: (row) => row.companyName.toLowerCase(),
          cell: (row) => (
            <div className="flex w-full flex-col gap-1 text-gray-dark">
              <span>{row.companyName}</span>
              <span className="line-clamp-1 text-gray-600">{row.title}</span>
            </div>
          ),
          sortable: true,
          width: '280px',
        },
        {
          id: 'category',
          name: 'Category',
          selector: (row) => row.customData?.categoryid,
          sortFunction: (rowA, rowB) => {
            // If there's no category id, sort to the bottom
            if (rowA.customData?.categoryid === null) {
              return 1
            }

            if (rowB.customData?.categoryid === null) {
              return -1
            }

            const a = getCategoryFromId(
              rowA.customData?.categoryid,
              registrationSyncData?.attendeeCategories,
            )?.toLowerCase()

            const b = getCategoryFromId(
              rowB.customData?.categoryid,
              registrationSyncData?.attendeeCategories,
            )?.toLowerCase()

            // If there's no category, sort to the bottom
            if (!a) {
              return 1
            }

            if (!b) {
              return -1
            }

            if (a > b) {
              return 1
            }

            if (b > a) {
              return -1
            }

            return 0
          },
          cell: (row) => {
            if (!registrationSyncData?.attendeeCategories || !row.customData?.categoryid)
              return null

            const category = getCategoryFromId(
              row.customData?.categoryid,
              registrationSyncData?.attendeeCategories,
            )

            return configureCategoryTag(category)
          },
          sortable: true,
          width: '180px',
          omit: !registrationSyncEnabled,
        },
        {
          id: 'edit',
          cell: (row) => (
            <button
              className={mergeClassNames(
                'font-bold text-blue',
                row.customData?.registrationstatus === 'In-progress' &&
                  'cursor-not-allowed opacity-25',
              )}
              disabled={row.customData?.registrationstatus === 'In-progress'}
              type="button"
              onClick={() => {
                if (activeTab === 'attendee') setAttendeeToEdit(row)
                else setQuickBadgeDuplicate(row)
              }}
            >
              {activeTab === 'attendee' ? 'Edit' : 'Duplicate'}
            </button>
          ),
          width: '100px',
          center: true,
        },
      ],
      [
        activeTab,
        selectedAttendees,
        selectedQuickBadges,
        registrationSyncEnabled,
        registrationSyncData,
      ],
    )

    const renderLastUpdated = () => {
      if (activeTab === 'attendee') {
        return (
          <div className="mr-2 mt-2 text-right text-xs text-gray-600">
            Last Update: {dayjs(attendeeLastUpdated).format('M/D/YY h:mm A')}
          </div>
        )
      }

      return (
        <div className="mr-2 mt-2 text-right text-xs text-gray-600">
          Last Update: {dayjs(quickBadgeLastUpdated).format('M/D/YY h:mm A')}
        </div>
      )
    }

    const configureBulkPrintButtonIsDisabled = () => {
      if (configuration?.requireKioskPhotoVerification) {
        return true
      }

      if (activeTab === 'attendee') {
        return selectedAttendees.length < 2
      }

      return selectedQuickBadges.length < 2
    }

    return (
      <div className="flex h-full max-h-[600px] flex-col">
        <div className="mb-2 flex justify-between">
          <div className="flex items-center gap-4">
            <button
              className={mergeClassNames(
                'text-md border-b-2 pb-2 font-medium text-purple',
                activeTab === 'attendee'
                  ? 'border-purple'
                  : 'border-b-transparent text-gray-600 hover:border-b-purple hover:text-purple',
              )}
              type="button"
              onClick={() => setActiveTab('attendee')}
            >
              Attendee
            </button>

            <button
              className={mergeClassNames(
                'text-md border-b-2 pb-2 font-medium text-purple',
                activeTab === 'quickBadges'
                  ? 'border-purple'
                  : 'border-b-transparent text-gray-600 hover:border-b-purple hover:text-purple',
              )}
              type="button"
              onClick={() => setActiveTab('quickBadges')}
            >
              Quick Badges
            </button>
          </div>

          <div className="flex items-center gap-4 self-start">
            <Button
              background="bg-teal"
              icon={<AddUserIcon className="h-5 fill-black sm:h-6" />}
              label="Add Quick Badge"
              onClick={() => setShowQuickBadgeModal(true)}
            />

            <Button
              disabled={configureBulkPrintButtonIsDisabled()}
              icon={<PrinterIcon className="h-5 fill-white" />}
              label={configureBulkPrintLabel()}
              onClick={() => {
                let filteredAttendees = []

                if (activeTab === 'attendee') {
                  // Filter out attendees that can't be checked in with a bulk print
                  filteredAttendees = _.filter(selectedAttendees, (a) => {
                    if (
                      a.customData?.registrationstatus !== 'Confirmed' &&
                      a.customData?.registrationstatus !== 'Attended'
                    )
                      return false
                    if (a.customData?.balance_due > 0) return false
                    // Attendees needing GDPR consent don't need to answer it again after being checked in
                    if (!a.isCheckedIn && GDPR_COUNTRIES.includes(a.customData?.country))
                      return false
                    return true
                  })

                  _.forEach(filteredAttendees, (a) => {
                    // Only update check-in status if the attendee hasn't checked-in already
                    if (!a.isCheckedIn && !a.quickBadge) {
                      // Mark attendee as checked in
                      updateAttendee(eventId, a.verificationId, {
                        isCheckedIn: true,
                        checkInLocation: location.id,
                        checkInLocationName: name,
                        checkedInAt: new Date(),
                      })
                    }

                    // Log Badge Print
                    const printLog = {
                      printedAt: new Date(),
                      id: `${uuid4()}`,
                      printedAtLocation: location.id,
                      printedAtLocationName: name,
                    }
                    if (a.quickBadge) {
                      printLog.quickBadge = a.id
                    } else {
                      printLog.attendee = a.id
                    }

                    addBadgePrintLog(eventId, printLog)
                  })
                } else {
                  filteredAttendees = selectedQuickBadges
                }

                handleBulkPrint(filteredAttendees)

                // Clear selected attendees
                if (activeTab === 'attendee') setSelectedAttendees([])
                else setSelectedQuickBadges([])
              }}
            />
          </div>
        </div>

        <div className="flex flex-row gap-4">
          <TextInput
            ref={searchInputRef}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[420px] rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
            placeholder="Search"
            endIcon={
              searchTerm ? (
                <button
                  type="button"
                  onClick={() => {
                    setSearchTerm('')
                    searchInputRef.current.value = ''
                  }}
                >
                  <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                </button>
              ) : null
            }
          />

          <Select
            className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            options={SEARCH_BY_OPTIONS}
            onChange={(o) => setSearchBy(o.id)}
            value={_.find(SEARCH_BY_OPTIONS, (o) => o.id === searchBy)}
            style={{ flex: true, width: '150px' }}
          />
        </div>

        <div className="mt-4 flex min-h-[0px] flex-col">
          <DataTable
            className="w-full"
            columns={COLUMNS}
            data={activeTab === 'attendee' ? attendees : quickBadges}
            defualtSortAsc
            defaultSortField="name"
            defaultSortFieldId="name"
          />
        </div>

        <div className="mb-2 flex justify-end">{renderLastUpdated()}</div>

        <div className="absolute bottom-2 mt-8 flex items-center gap-2">
          <QRIcon className="h-4 fill-black" />
          {name}
        </div>

        {(showQuickBadgeModal || quickBadgeDuplicate) && (
          <QuickBadgeModal
            baseQuickBadge={quickBadgeDuplicate}
            handleCheckIn={setAttendeeToCheckIn}
            eventId={eventId}
            registrationSyncData={registrationSyncData}
            closeModal={() => {
              setQuickBadgeDuplicate(null)
              setShowQuickBadgeModal(false)
            }}
          />
        )}

        {attendeeToEdit && (
          <EditAttendeeModal
            attendeeToEdit={attendeeToEdit}
            closeModal={() => setAttendeeToEdit(null)}
            deviceLocations={configuration?.kioskDeviceLocations}
            handleCheckIn={(attendee) => {
              setAttendeeToCheckIn(attendee)
              setAttendeeToEdit(null)
            }}
            externalPopupField={configuration?.externalPopupField}
            registrationSyncData={registrationSyncData}
            registrationSyncEnabled={registrationSyncEnabled}
          />
        )}
      </div>
    )
  },
)

Staff.propTypes = {
  handleBulkPrint: PropTypes.func.isRequired,
  registrationSyncData: PropTypes.object.isRequired,
  registrationSyncEnabled: PropTypes.bool.isRequired,
  setAttendeeToCheckIn: PropTypes.func.isRequired,
}

export { Staff }
