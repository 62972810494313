import React, { Fragment, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { usePopper } from 'react-popper'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { BackIcon } from '../BackIcon'
import { Button } from '../Button'
import { MfaModal } from '../MfaModal'
import { OrganizationIcon } from '../OrganizationIcon'

// Images
import Eventstack from '../../assets/images/eventstack.svg'
import EventstackLogo from '../../assets/images/eventstack_logo.svg'
import FetchLogo from '../../assets/images/fetch_text_logo.svg'
import UserPlaceholder from '../../assets/images/user_placeholder.svg'

// Stores
import { RootStoreContext } from '../../stores/RootStore'

// Service
import { getEvent } from '../../services/events.service'
import { getOrganization } from '../../services/organizations.service'
import { logout } from '../../services/user.service'

// Utils
import { joinClassNames } from '../../utils/helpers'
import routes from '../../pages/Routes/RouteConfig'
import { MENU_OPTIONS, getHighLevelNavigationOptions, getNavigationOptions } from './helpers'

/**
 *
 * NavBar
 *
 */
const NavBar = observer(({ children }) => {
  // Context
  const {
    navigation: {
      event,
      eventId,
      organization,
      organizationId,
      setEvent,
      setEventId,
      setOrganization,
      resetEvent,
      resetNavigation,
      type,
      updateActiveEntity,
    },
    user: {
      getUpdatedUser,
      isAuthenticated,
      isEEUser,
      refreshToken,
      setCurrentUser,
      updateUser,
      user,
    },
    clearStore,
  } = useContext(RootStoreContext)
  const navigate = useNavigate()
  const location = useLocation()
  const mediumScreen = useMediaQuery({
    query: '(min-width: 768px)',
  })

  // State
  const [configuration, setConfiguration] = useState({
    type: null,
    options: [],
    urlPrefix: '',
  })
  const [error, setError] = useState(null)

  // MFA Verification
  const [showMfaReminder, setShowMfaReminder] = useState(false)
  const [profileButton, setProfileButton] = useState(null)
  const [mfaPopover, setMfaPopover] = useState(null)

  const { styles, attributes } = usePopper(profileButton, mfaPopover, {
    modifiers: [{ name: 'offset', options: { offset: [-110, 4] } }],
  })

  const isAdminRoute =
    _.find(
      ['/dashboard', '/users', '/organizations', '/profile', '/reports', '/settings'],
      (u) => location.pathname === u,
    ) !== undefined
  // Checks if the current path is an Organization, Exhibitor or Event specific route
  const isOrganizationRoute =
    location.pathname.includes('/organization') && location.pathname !== '/organizations'
  const isExhibitorRoute = location.pathname.includes('/exhibitor')
  const isEventRoute = location.pathname.includes('/event')

  useEffect(() => {
    if (user !== undefined && user.id) getUpdatedUser(user.id)
  }, [])

  /**
   * Get updated organization information when the user navigates to a new organization
   */
  useEffect(() => {
    if (organizationId) {
      const loadData = async () => {
        const org = await getOrganization(
          organizationId,
          setError,
          () => {},
          () => {},
        )
        setOrganization(org)
      }

      loadData()
    }
  }, [organizationId])

  const getUpdatedEvent = async () => {
    const e = await getEvent(
      organizationId,
      eventId,
      setError,
      () => {},
      () => {},
    )
    setEvent(e)
  }

  const debounceGetUpdatedEvent = _.debounce(getUpdatedEvent, 500)

  /**
   * Get updated event information when the user navigates to a new event
   */
  useEffect(() => {
    // Don't pull event when not on an event dashboard
    // clear event in navigation when only on org or exhibitor dashboard
    if (eventId && isEventRoute && organizationId) {
      debounceGetUpdatedEvent()
    }

    // Clear active navigation when on admin route
    // There is no need to store organization and event information since we aren't
    // in a nested dashboard when viewing admin routes.
    if (isAdminRoute && organizationId && organization) {
      resetNavigation()
    }

    // If we aren't on an event route, reset the event stored.
    // There is no need to store event information when we aren't on an event dashboard.
    if (!isEventRoute && eventId && event) {
      resetEvent()
    }
  }, [eventId, organizationId, location.pathname])

  /**
   * This will trigger once the user authenticates. We need to check to see
   * if they are on a public route, and if so, set the navigation bar accordingly.
   */
  useEffect(() => {
    const publicRoutes = _.map(
      _.filter(routes, (r) => r.type === 'public'),
      (r) => r.path,
    )
    const isPublicRoute = _.find(publicRoutes, (r) => location.pathname === r) !== undefined

    // If were navigating from a public route, set up the navigation bar on first load
    if (isPublicRoute) {
      // EE Users get directed to the EE dashboard
      if (isEEUser) {
        setConfiguration({
          ...configuration,
          type: 'admin',
          options: getNavigationOptions('admin', event, `/organization/${organizationId}`, true),
        })
      }
      // Otherwise, we display the organization dashboard
      else {
        setConfiguration({
          ...configuration,
          type: 'organization',
          options: getNavigationOptions('organization', null, `/${type}/${organizationId}`),
        })
      }
    }
  }, [isAuthenticated, isEEUser])

  /**
   * This will trigger when the user is authenticated and navigates to a new page.
   * We want to update the navigation bar if the user is navigating to different
   * dashboards.
   */
  useEffect(() => {
    // If the user is navigating to the EE dashboard from an Organization dashboard, update the bar and options displayed
    if (isAdminRoute && isEEUser && configuration.type !== 'admin') {
      setConfiguration({
        type: 'admin',
        options: getNavigationOptions('admin', event, `/organization/${organizationId}`, true),
        urlPrefix: '',
      })
    }
    // If the user is navigating to an Organization or Exhibitor route and not to a specific event, update the bar
    // and options displayed
    else if ((isOrganizationRoute || isExhibitorRoute) && !isEventRoute) {
      const urlPrefix =
        type === 'exhibitor' ? `/exhibitor/${organizationId}` : `/organization/${organizationId}`

      const updatedOptions = getNavigationOptions(
        type === 'exhibitor' ? 'exhibitor-organization' : 'organizer-organization',
        null,
        urlPrefix,
        isEEUser,
      )

      setConfiguration({
        type: 'organization',
        options: updatedOptions,
        urlPrefix,
      })
    }
    // If the user is navigating to an Event route, update the bar and options displayed
    else if (isEventRoute && event) {
      const urlPrefix =
        type === 'exhibitor' ? `/exhibitor/${organizationId}` : `/organization/${organizationId}`

      setConfiguration({
        type: 'organization',
        options: getNavigationOptions(
          type === 'exhibitor' ? 'exhibitor-event' : 'organizer-event',
          event,
          configuration.urlPrefix,
          isEEUser,
        ),
        urlPrefix,
      })
    }
  }, [event, isEEUser, location.pathname, configuration.urlPrefix])

  // If no `accessToken`, render w/out navigation bar
  if (!isAuthenticated) {
    return <div className="flex h-full w-screen flex-col">{children}</div>
  }

  const urlType = location.pathname.split('/')[1]
  const urlOrganizationId = location.pathname.split('/')[2]
  const urlEventId = location.pathname.split('/')[4]

  // If the user navigates directly to an organization or event, update the active entity
  // or the event id.
  if (
    organizationId !== urlOrganizationId &&
    (urlType === 'organization' || urlType === 'exhibitor') &&
    !error
  ) {
    updateActiveEntity(urlType, urlOrganizationId)
  }
  if (urlEventId && eventId !== urlEventId && !error) {
    setEventId(urlEventId)
  }

  /**
   * If the user is authenticated, but we haven't loaded the organization or event,
   * display a loading indicator.
   */
  if (((organizationId && !organization) || (eventId && !event)) && !error) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
        <span className="text-2xl font-bold">Loading...</span>
        <span className="flex items-center pr-3">
          <div className="h-10 w-10">
            {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
            <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
              <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
            </svg>
          </div>
        </span>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center space-y-4">
        <span className="text-2xl font-bold">Cannot access organization or event.</span>

        <Button
          label="Back to Dashboard"
          background="bg-purple border-purple hover:bg-purple-600"
          onClick={() => {
            // If ee user navigate using navigation context
            if (isEEUser) {
              resetNavigation()
              updateActiveEntity(null, null)
              navigate('/dashboard', { replace: true })
            }
            // Otherwise, use the user's active organization
            else {
              const activeType =
                user.activeOrganization.type === 'Organizer' ? 'organization' : 'exhibitor'

              resetEvent()
              updateActiveEntity(activeType, user.activeOrganization.id)
              navigate(`${activeType}/${user.activeOrganization.id}/dashboard`, { replace: true })
            }

            setError(null)
          }}
        />
      </div>
    )
  }

  if (isEEUser === null || configuration.type === null || configuration.options === null) {
    return children ? <div>{children}</div> : null
  }

  /**
   * Handles updating the current user's active organization with the
   * specified `updatedOrganization`.
   * @param {object} updatedOrganization
   */
  const handleOrganizationChange = async (updatedOrganization) => {
    // Set the correct organization type
    let orgType = ''
    if (updatedOrganization.type === 'Organizer') orgType = 'organization'
    else if (updatedOrganization.type === 'Exhibitor') orgType = 'exhibitor'

    // If the user has an active role for this organization, update it
    const hasRole = _.find(
      user.organizationRoles,
      (o) => o.organization.id === updatedOrganization.id,
    )
    if (hasRole) {
      await updateUser(
        { id: user.id, activeOrganization: updatedOrganization.id },
        () => {},
        () => {},
        () => {},
        setCurrentUser,
      )
    }

    // Update the active entity and navigate to the dashboard
    updateActiveEntity(orgType, updatedOrganization.id)
    navigate(`/${orgType}/${updatedOrganization.id}/dashboard`)
  }

  /**
   * Configures navigation item with styling
   * - Display suboptions when applicable
   * @param {object} option
   * @param {bool} smallScreen
   * @param {function } close
   * @returns
   */
  const configureNavItem = (option, smallScreen = false, close = null) => {
    let isActive = location.pathname.includes(option.path)

    return (
      <Disclosure defaultOpen={isActive}>
        {() => {
          const hasActiveSubOption = _.find(option.options, (o) => o.regex.test(location.pathname))
          isActive = isActive || hasActiveSubOption !== undefined

          let style = ''
          if (isActive && option.type !== 'back' && smallScreen) {
            style = 'text-white bg-darkBlue-dark px-2 py-1 stroke-white fill-white'
            if (option.options) style += ' rounded-t-md'
            else style += ' rounded-md'
          } else if (isActive && option.type !== 'back') {
            style = 'bg-teal text-dark rounded-md stroke-dark'
          } else if (option.type !== 'back') {
            style =
              'hover:text-white md:hover:bg-teal md:hover:text-dark rounded-md text-white stroke-white fill-white hover:stroke-dark hover:fill-dark'
          } else if (option.type === 'back') {
            style = 'hover:text-teal text-white hover:fill-teal fill-white'
          }

          return (
            <div className="md:space-y-1">
              <DisclosureButton
                className={joinClassNames(
                  'flex w-full items-center px-2 py-2 text-sm font-medium',
                  style,
                )}
                key={option.label}
                onClick={() => {
                  if (option.type === 'back') resetEvent()

                  if (option.options) {
                    if (option.options[0].path) navigate(option.options[0].path)
                    if (smallScreen && close) close()
                  } else if (option.onClick) {
                    option.onClick()
                  } else {
                    navigate(option.path)
                    if (close) close()
                  }
                }}
              >
                {option.type === 'back' && (
                  <div className="mr-2">
                    <BackIcon className="fill-inherit" />
                  </div>
                )}
                {option.icon && (
                  <div className="mr-2 fill-inherit stroke-inherit">{option.icon}</div>
                )}
                {option.label}
              </DisclosureButton>

              {option.options && (
                <Transition
                  show={isActive}
                  as={Fragment}
                  enter="transition-all ease-in duration-500"
                  enterFrom="transform opacity-0 -top-auto"
                  enterTo="transform opacity-100 top-0"
                  leave="transition-all ease-out duration-0"
                  leaveFrom="transform opacity-100 top-0"
                  leaveTo="transform opacity-0 -top-auto"
                >
                  <DisclosurePanel className="space-y-1 rounded-b-md bg-darkBlue-dark p-1 md:rounded-md">
                    {option.options.map((subOption) => {
                      const isActiveOption = subOption.regex.test(location.pathname)

                      return (
                        <button
                          type="button"
                          key={subOption.label}
                          className={joinClassNames(
                            'group flex w-full items-center px-2 py-1.5 text-xs font-medium',
                            isActiveOption ? 'text-white' : 'text-gray-600 hover:text-white',
                          )}
                          onClick={() => {
                            if (subOption.onClick) subOption.onClick()
                            else navigate(subOption.path)
                            if (close) close()
                          }}
                        >
                          {subOption.label}
                        </button>
                      )
                    })}
                  </DisclosurePanel>
                </Transition>
              )}
            </div>
          )
        }}
      </Disclosure>
    )
  }

  const renderHighLevelNavOptions = () => {
    const options = getHighLevelNavigationOptions(
      type,
      configuration.urlPrefix,
      isEventRoute,
      isEEUser,
    )

    return _.map(_.keys(options), (key) => (
      <Disclosure key={key} defaultOpen={false}>
        {({ open }) => (
          <div className="md:space-y-1">
            <DisclosureButton
              className={mergeClassNames(
                'flex w-full items-center rounded-t-md p-1 px-2 py-2 text-sm font-medium text-white md:rounded-md',
                open && 'bg-darkBlue-dark',
              )}
              key={key}
            >
              {key}
            </DisclosureButton>

            {options[key] && (
              <Transition
                show={open}
                as={Fragment}
                enter="transition-all ease-in duration-500"
                enterFrom="transform opacity-0 -top-auto"
                enterTo="transform opacity-100 top-0"
                leave="transition-all ease-out duration-0"
                leaveFrom="transform opacity-100 top-0"
                leaveTo="transform opacity-0 -top-auto"
              >
                <DisclosurePanel className="space-y-1 rounded-b-md bg-darkBlue-dark p-1 md:rounded-md">
                  {options[key].map((o) => (
                    <button
                      type="button"
                      key={o.label}
                      className="group flex w-full items-center px-2 py-1.5 text-xs font-medium text-gray-600 hover:text-white"
                      onClick={() => navigate(o.path)}
                    >
                      {o.label}
                    </button>
                  ))}
                </DisclosurePanel>
              </Transition>
            )}
          </div>
        )}
      </Disclosure>
    ))
  }

  const menuOptions = MENU_OPTIONS(navigate, () => {
    logout(refreshToken, () => clearStore(user.activeOrganization?.type === 'Exhibitor' || false))
  })

  const renderMfaPopover = () =>
    showMfaReminder && (
      <div ref={setMfaPopover} style={styles.popper} {...attributes.popper}>
        <div className="flex w-96 flex-col rounded-lg bg-white px-2.5 py-2 shadow-md">
          <span className="text-xs">
            To configure Multi-Factor Authentication through text message, visit your Profile page
            and add a phone number.
          </span>

          <button
            className="place-self-end px-2 py-0.5 text-xs font-bold text-purple hover:rounded-full hover:bg-status-purple"
            onClick={() => setShowMfaReminder(false)}
            type="button"
          >
            Dismiss
          </button>
        </div>
      </div>
    )

  if (configuration.type === 'admin') {
    return (
      <div className="h-screen w-screen">
        <Disclosure as="nav" className="relative bg-gray-900 md:bg-dark">
          {({ open, close }) => (
            <div>
              {/* Purple Bottom Border */}
              <div
                className="absolute inset-x-0 bottom-0 mx-auto hidden h-[1.5px] w-[90%] md:block"
                style={{
                  background:
                    'linear-gradient(0deg, rgba(165, 180, 252, 0.25), rgba(165, 180, 252, 0.25)), #8C56F6',
                }}
              />

              <div className="relative flex h-16 justify-between px-2 md:px-6 lg:px-8">
                {/* Mobile Menu Button */}
                <div className="absolute inset-y-0 left-2 flex items-center md:hidden">
                  <DisclosureButton>
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block w-6 stroke-gray-500" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block w-6 stroke-gray-500" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>

                {/* Logo & Navigation Options */}
                <div className="flex flex-1 items-center justify-end md:justify-start">
                  {/* Logo */}
                  <div className="flex shrink-0 md:items-center">
                    <img
                      className="block h-auto w-11 md:hidden"
                      src={Eventstack}
                      alt="Eventstack"
                    />
                    <img
                      className="hidden h-auto w-28 md:block"
                      src={EventstackLogo}
                      alt="Eventstack"
                    />
                  </div>

                  {/* Options */}
                  <div className="hidden md:ml-6 md:flex md:space-x-4">
                    {_.map(configuration.options, (option) => (
                      <button
                        key={option.label}
                        className={joinClassNames(
                          location.pathname === option.path
                            ? 'bg-teal text-dark'
                            : 'text-white hover:bg-teal hover:text-dark',
                          'group rounded-md border-solid px-3 py-2 text-sm font-medium',
                        )}
                        onClick={() => {
                          if (option.onClick) option.onClick()
                          if (option.path) navigate(option.path)
                        }}
                        type="button"
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Avatar & Username */}
                <div className="absolute inset-y-0 right-0 hidden items-center pr-2 md:static md:inset-auto md:ml-6 md:flex md:pr-0">
                  <Menu as="div" className="relative ml-3">
                    <MenuButton
                      className="ml-3 flex flex-row items-center space-x-2"
                      ref={setProfileButton}
                    >
                      <span className="text-sm font-medium text-white">
                        {user.firstName} {user.lastName}
                      </span>
                      <img
                        src={user.signedProfileImageUrl || UserPlaceholder}
                        alt="User"
                        className="h-[32px] w-[32px] rounded-full bg-white object-cover"
                      />
                    </MenuButton>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                        {_.map(menuOptions, (option) => (
                          <MenuItem key={option.label}>
                            {({ active: a }) => (
                              <button
                                type="button"
                                className={joinClassNames(
                                  a ? 'bg-gray-100 text-purple-700' : '',
                                  'block w-full px-4 py-2 text-left text-sm text-gray-900',
                                )}
                                onClick={option.onClick}
                              >
                                {option.label}
                              </button>
                            )}
                          </MenuItem>
                        ))}
                      </MenuItems>
                    </Transition>
                  </Menu>
                </div>
              </div>

              {/* Mobile Menu */}
              <DisclosurePanel className="md:hidden">
                <div className="flex flex-col space-y-1 px-2 pb-4 pt-2">
                  {_.map(configuration.options, (option) => configureNavItem(option, true, close))}
                  <div className="h-[1.5px] bg-gray" />
                  <div className="flex flex-row items-center space-x-3 py-3 pl-3">
                    <img
                      src={user.signedProfileImageUrl || UserPlaceholder}
                      alt="User"
                      className="w-10 rounded-full bg-white"
                    />

                    <div className="flex flex-col">
                      <span className="text-md font-medium text-white">
                        {user.firstName} {user.lastName}
                      </span>
                      <span className="text-sm font-medium text-gray-500">{user.email}</span>
                    </div>
                  </div>
                  {_.map(menuOptions, (option) => (
                    <DisclosureButton
                      as="a"
                      href={option.path}
                      className={joinClassNames(
                        location.pathname === option.path
                          ? 'bg-darkBlue-dark text-white'
                          : 'text-gray-500 hover:bg-teal hover:text-dark',
                        'text-md rounded-md px-2 py-2 font-medium text-gray-500',
                      )}
                      key={option.label}
                      onClick={option.onClick}
                    >
                      <span>{option.label}</span>
                    </DisclosureButton>
                  ))}
                </div>
                <div className="flex flex-row items-center justify-center space-x-2 bg-darkBlue-dark py-1">
                  <span className="text-md text-white">Powered by</span>
                  <img src={EventstackLogo} alt="Eventstack" className="w-28" />
                </div>
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>

        <div className="h-[calc(100vh-64px)] w-screen">{children}</div>

        {!user.hasViewedMfaNotification && (
          <MfaModal
            setCurrentUser={setCurrentUser}
            setShowMfaReminder={setShowMfaReminder}
            user={user}
          />
        )}

        {renderMfaPopover()}
      </div>
    )
  }

  const renderOrganization = () => (
    <div className="flex items-center justify-center space-x-3">
      {user.organizationRoles.length > 1 ? (
        <Listbox
          className="disabled:opacity-50"
          onChange={handleOrganizationChange}
          value={organization}
        >
          {({ open }) => (
            <div className="flex w-full flex-col place-items-start">
              <div className="w-auto">
                <ListboxButton
                  className="relative w-auto cursor-pointer py-2.5 text-left font-nunito text-white shadow-none outline-none sm:text-gray-900"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex flex-row place-items-center gap-2">
                    <OrganizationIcon className="h-[18px] w-[18px] stroke-white md:stroke-black" />
                    <span className="text-xl font-bold text-white md:text-black">
                      {organization && organization.name}
                    </span>
                    <span className="pointer-events-none inset-y-0 flex items-center">
                      <ChevronUpDownIcon className="text-primary h-5 w-5" aria-hidden="true" />
                    </span>
                  </div>
                </ListboxButton>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions
                    className="absolute z-20 mt-1 max-h-60 w-auto min-w-[250px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-300 focus:outline-none sm:text-sm"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {user.organizationRoles.map((o) => (
                      <ListboxOption
                        key={o.id}
                        className="relative cursor-pointer select-none px-3 py-2 text-gray-900 hover:bg-purple hover:text-white"
                        value={o.organization}
                      >
                        {() => (
                          <div className="justify-items-between flex w-full">
                            <span
                              className={joinClassNames(
                                organization && o.organization.id === organization.id
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              )}
                            >
                              {o.organization.name}
                            </span>

                            {organization && o.organization.id === organization.id ? (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-purple">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </div>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
      ) : (
        <>
          <OrganizationIcon className="h-[18px] w-[18px] stroke-white md:stroke-black" />
          <span className="text-xl font-bold text-white md:text-black">
            {organization && organization.name}
          </span>
        </>
      )}
    </div>
  )

  const showBackToEventList =
    isEventRoute &&
    type === 'exhibitor' &&
    (isEEUser || user.activeOrganization.type === 'Organizer')

  return (
    // Mobile Navigation
    !mediumScreen ? (
      <div className="flex h-screen w-screen flex-col md:hidden">
        <Disclosure
          as="nav"
          className={mergeClassNames(
            'relative bg-gray-900',
            location.pathname.includes('kiosk') && 'hidden',
          )}
        >
          {({ open, close }) => (
            <>
              <div className="relative flex h-16 justify-between px-2">
                {/* Mobile menu */}
                <div className="ml-2 flex items-center">
                  <DisclosureButton>
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block w-6 stroke-gray-500" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block w-6 stroke-gray-500" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>

                {/* Organization */}
                {renderOrganization()}

                {/* Logo */}
                <div className="flex shrink-0 md:items-center">
                  <img className="block h-auto w-11" src={Eventstack} alt="Eventstack" />
                </div>
              </div>

              {/* Mobile menu */}
              <DisclosurePanel>
                <div className="flex flex-col overflow-y-scroll">
                  <div className="space-y-1 px-2">
                    {showBackToEventList && (
                      <Button
                        fullWidth
                        label="Back to Event Exhibitor List"
                        onClick={() =>
                          navigate(
                            `organization/${event.organizer}/event/${event.id}/lead-retrieval/exhibitors`,
                          )
                        }
                      />
                    )}

                    {_.map(configuration.options, (o) => configureNavItem(o, true, close))}

                    {(isOrganizationRoute || isExhibitorRoute) && (
                      <>
                        <div className="h-[1.5px] bg-gray" />
                        <div className="space-y-2">{renderHighLevelNavOptions()}</div>
                      </>
                    )}

                    <div className="h-[1.5px] bg-gray" />

                    <div className="flex flex-row items-center space-x-3 py-3 pl-3">
                      <img
                        src={user.signedProfileImageUrl || UserPlaceholder}
                        alt="User"
                        className="w-10 rounded-full bg-white"
                      />

                      <div className="flex flex-col">
                        <span className="text-md font-medium text-white">
                          {user.firstName} {user.lastName}
                        </span>
                        <span className="text-sm font-medium text-gray-500">{user.email}</span>
                      </div>
                    </div>

                    <div className="flex flex-col gap-6 pb-4 pl-3 pt-3">
                      {_.map(menuOptions, (option) => (
                        <button
                          type="button"
                          className={joinClassNames(
                            'text-md flex flex-row items-center space-x-2 font-medium text-gray-500',
                          )}
                          onClick={option.onClick}
                        >
                          <span>{option.label}</span>
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-row items-center justify-center space-x-2 bg-darkBlue-dark py-1">
                    <span className="text-md text-white">Powered by</span>
                    <img src={EventstackLogo} alt="Eventstack" className="w-28" />
                  </div>
                </div>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>

        <div className="flex h-full w-full overflow-y-auto bg-background md:hidden">
          {children}
        </div>

        {!user.hasViewedMfaNotification && (
          <MfaModal
            setCurrentUser={setCurrentUser}
            setShowMfaReminder={setShowMfaReminder}
            user={user}
          />
        )}

        {renderMfaPopover()}
      </div>
    ) : (
      // Desktop Nav
      <div className="flex h-screen w-screen flex-row bg-background p-[10px]">
        <div
          className={mergeClassNames(
            'flex w-64 shrink-0 flex-col overflow-y-auto rounded-[20px] bg-darkBlue pt-5',
            location.pathname.includes('kiosk') && 'hidden',
          )}
        >
          <div className="flex justify-center">
            {type === 'exhibitor' ? (
              <img className="block h-auto w-[160px]" src={FetchLogo} alt="Fetch" />
            ) : (
              <img className="block h-auto w-[160px]" src={EventstackLogo} alt="Eventstack" />
            )}
          </div>

          <nav className="mt-5 flex h-full flex-col justify-between">
            <div className="space-y-2 px-2">
              {showBackToEventList && (
                <Button
                  fullWidth
                  label="Back to Event Exhibitor List"
                  onClick={() =>
                    navigate(
                      `organization/${event.organizer}/event/${event.id}/lead-retrieval/exhibitors`,
                    )
                  }
                />
              )}

              {_.map(configuration.options, (o) => configureNavItem(o))}
            </div>

            {(isOrganizationRoute || isExhibitorRoute) && (
              <div className="space-y-2 px-2 pb-4">{renderHighLevelNavOptions()}</div>
            )}
          </nav>

          {type === 'exhibitor' && (
            <div className="flex flex-col items-center justify-center pb-4">
              <span className="relative -bottom-2 ml-6 text-[10px] text-white">Powered by</span>
              <img src={EventstackLogo} alt="Eventstack" className="w-44" />
            </div>
          )}
        </div>

        <div className="ml-3 h-full w-full space-y-1 pb-3">
          <div
            className={mergeClassNames(
              'relative flex h-16 justify-between rounded-[20px] bg-white px-7',
              location.pathname.includes('kiosk') && 'hidden',
            )}
          >
            {/* Organization */}
            {renderOrganization()}

            {/* Avatar dropdown */}
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <Menu as="div" className="relative ml-3">
                <MenuButton
                  className="ml-3 flex flex-row items-center space-x-2"
                  ref={setProfileButton}
                >
                  <span className="text-primary text-sm font-medium">
                    {user.firstName} {user.lastName}
                  </span>
                  <img
                    src={user.signedProfileImageUrl || UserPlaceholder}
                    alt="User"
                    className="h-[32px] w-[32px] rounded-full bg-white object-cover"
                  />
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                    {_.map(menuOptions, (option) => (
                      <MenuItem key={option.label}>
                        {({ active: a }) => (
                          <button
                            type="button"
                            className={joinClassNames(
                              a ? 'bg-gray-100 text-purple-700' : '',
                              'block w-full px-4 py-2 text-left text-sm text-gray-900',
                            )}
                            onClick={option.onClick}
                          >
                            {option.label}
                          </button>
                        )}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </div>

          <div className="flex h-[calc(100vh-78px)] w-full overflow-y-scroll">{children}</div>
        </div>

        {!user.hasViewedMfaNotification && (
          <MfaModal
            setCurrentUser={setCurrentUser}
            setShowMfaReminder={setShowMfaReminder}
            user={user}
          />
        )}

        {renderMfaPopover()}
      </div>
    )
  )
})

export default NavBar
