import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { v4 as uuid4 } from 'uuid'

// Components
import { AdminOverride } from '../../pages/EventKiosk/AdminOverride'
import { ErrorPopup } from './ErrorPopup'
import { PhotoVerificationPopup } from './PhotoVerificationPopup'
import { PrivacyPopup } from './PrivacyPopup'
import { VerificationPopup } from './VerificationPopup'
import { WelcomePopup } from './WelcomePopup'

// Utils & Service
import { addBadgePrintLog, updateAttendee } from '../../services/firestore/firestore.service'
import { GDPR_COUNTRIES } from '../../utils/constants'

const POPUP_ORDER = ['verify', 'gdpr', 'welcome']
const ERRORS = [
  'Cancelled',
  'Incomplete',
  'In-progress',
  'Inactive Waitlist',
  'No Show',
  'Declined',
  'No Response',
  'Declined',
  'Visited',
  'Waitlist',
  'Pending Approval',
  'Denied',
]

const KioskPopups = ({
  attendee,
  clearAttendee,
  configuration,
  eventId,
  handlePrintBadge,
  locationId,
  mode,
  name,
}) => {
  // State
  const [updatedAttendee, setUpdatedAttendee] = useState(attendee)
  const [popups, setPopups] = useState(POPUP_ORDER)
  const [popupContent, setPopupContent] = useState(null)
  const [activePopup, setActivePopup] = useState(null)
  const [showAdminPassword, setShowAdminPassword] = useState(false)

  useEffect(() => {
    if (configuration && configuration.kioskPopups && mode) {
      const popupsToDisplay = _.filter(
        configuration.kioskPopups,
        (p) => p.identifier.includes(mode) || p.identifier === 'gdpr',
      )
      setPopupContent(popupsToDisplay)
    }
  }, [configuration, mode])

  /**
   * Resets the Check-In process
   * - Clears the active popup
   * - Clears the attendee
   */
  const resetCheckIn = () => {
    setActivePopup(null)
    clearAttendee(null)
  }

  /**
   * Marks the attendee as checked in
   * - Updates the attendee in Firestore
   * - Prints the badge
   * - Clears the attendee
   */
  const markAttendeeCheckedIn = () => {
    // Only update check-in status if the attendee hasn't checked-in already
    if (!updatedAttendee.isCheckedIn && !updatedAttendee.quickBadge) {
      // Mark attendee as checked in
      updateAttendee(eventId, updatedAttendee.verificationId, {
        isCheckedIn: true,
        checkInLocation: locationId,
        checkInLocationName: name,
        checkedInAt: new Date(),
      })
    }

    // Log Badge Print
    const printLog = {
      printedAt: new Date(),
      id: `${uuid4()}`,
      printedAtLocation: locationId,
      printedAtLocationName: name,
    }
    if (updatedAttendee.quickBadge) {
      printLog.quickBadge = updatedAttendee.id
    } else {
      printLog.attendee = updatedAttendee.id
    }

    addBadgePrintLog(eventId, printLog)

    // Print badge
    handlePrintBadge(updatedAttendee)
  }

  const configureNextPopup = () => {
    // Only display the verification popup if the following conditions are met:
    // - The configuration allows for verification
    // - The verification popup is in the list of popups to display
    // - The mode is not staff
    if (configuration.useVerification && popups.includes('verify') && mode !== 'staff') {
      setActivePopup('verify')
    } else if (
      configuration.requireKioskPhotoVerification &&
      popups.includes('verify') &&
      mode === 'staff'
    ) {
      setActivePopup('photo-verify')
    } else if (updatedAttendee.isCheckedIn) {
      setActivePopup('reprint')
    }
    // If we need to display an error popup (order: status-error, balance-due, reprint)
    else if (
      (updatedAttendee.customData?.registrationstatus &&
        _.includes(ERRORS, updatedAttendee.customData.registrationstatus)) ||
      (updatedAttendee.customData?.registrationstatus &&
        _.includes(ERRORS, updatedAttendee.customData.registrationstatus))
    ) {
      setActivePopup('status-error')
    } else if (updatedAttendee.customData?.balance_due > 0) {
      setActivePopup('balance-due')
    }
    // If we need to verify GDPR
    else if (
      updatedAttendee.customData?.country &&
      GDPR_COUNTRIES.includes(updatedAttendee.customData.country) &&
      popups.includes('gdpr')
    ) {
      setActivePopup('gdpr')
    }
    // Otherwise, display the welcome popup
    else {
      setActivePopup('welcome')
    }
  }

  useEffect(() => {
    configureNextPopup()
  }, [popups])

  useEffect(() => {
    if (attendee && configuration) {
      setUpdatedAttendee(attendee)
      setPopups(POPUP_ORDER)
    }
  }, [attendee])

  const configurePopupToDisplay = () => {
    if (showAdminPassword) {
      return (
        <AdminOverride
          configuration={configuration}
          setShow={() => setShowAdminPassword(false)}
          submit={() => {
            setShowAdminPassword(false)
            setActivePopup('welcome')
          }}
        />
      )
    }

    switch (activePopup) {
      case 'verify':
        return (
          <VerificationPopup
            attendee={updatedAttendee}
            verificationField={configuration.verificationField}
            close={() => resetCheckIn(null)}
            continueCheckIn={() => {
              setPopups(_.filter(popups, (p) => p !== 'verify'))
            }}
          />
        )
      case 'photo-verify':
        return (
          <PhotoVerificationPopup
            attendee={updatedAttendee}
            close={() => resetCheckIn(null)}
            continueCheckIn={() => {
              setPopups(_.filter(popups, (p) => p !== 'verify'))
            }}
          />
        )
      case 'status-error':
        return (
          <ErrorPopup
            attendee={updatedAttendee}
            config={_.find(popupContent, (p) => p.identifier.includes('status-error'))}
            close={() => resetCheckIn(null)}
          />
        )
      case 'reprint':
        return (
          <ErrorPopup
            attendee={updatedAttendee}
            config={_.find(popupContent, (p) => p.identifier.includes('reprint'))}
            close={() => resetCheckIn(null)}
            mode={mode}
            override={() => {
              setActivePopup(null)
              setShowAdminPassword(true)
            }}
          />
        )
      case 'balance-due':
        return (
          <ErrorPopup
            attendee={updatedAttendee}
            config={_.find(popupContent, (p) => p.identifier.includes('balance-due'))}
            close={() => resetCheckIn(null)}
            mode={mode}
            override={() => {
              setActivePopup(null)
              setShowAdminPassword(true)
            }}
          />
        )
      case 'gdpr':
        return (
          <PrivacyPopup
            attendee={updatedAttendee}
            config={_.find(popupContent, (p) => p.identifier.includes('gdpr'))}
            close={(optIn) => {
              // Update attendee with GDPR opt-in
              updateAttendee(eventId, updatedAttendee.verificationId, { gdprOptIn: optIn })
              setUpdatedAttendee({ ...updatedAttendee, gdprOptIn: optIn })

              setPopups(_.filter(popups, (p) => p !== 'gdpr'))
            }}
          />
        )
      case 'welcome':
        return (
          <WelcomePopup
            attendee={updatedAttendee}
            completeCheckin={() => markAttendeeCheckedIn()}
            config={_.find(popupContent, (p) => p.identifier.includes('welcome'))}
            customPopups={configuration.kioskCustomPopupMessages}
            close={() => resetCheckIn()}
            externalPopupField={configuration.externalPopupField}
            staffMode={mode === 'staff'}
          />
        )
      default:
        return null
    }
  }

  return configurePopupToDisplay()
}

KioskPopups.defaultProps = {
  attendee: null,
  configuration: null,
  mode: null,
}

KioskPopups.propTypes = {
  attendee: PropTypes.object,
  clearAttendee: PropTypes.func.isRequired,
  configuration: PropTypes.object,
  eventId: PropTypes.string.isRequired,
  handlePrintBadge: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  mode: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default KioskPopups
